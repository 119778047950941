import { Paper } from '@mui/material';
import { styled } from '@mui/system';

export const PaperContainer = styled('div')`
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const OrganizationPaper = styled(Paper)`
  width: 630px;
  min-height: 284px;
  height: fit-content;
  padding: 46px;
  margin-top: calc((100vh - 284px)/2);
`;

export const FormContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
`;

export const FromGroup = styled('div')`
  margin-bottom: 15px;
  max-width: 330px;
  align-self: center;
`;

export const Label = styled('label')`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const StandardInput = styled('input')`
  display: block;
  width: 305px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin: 0;
  padding: 0 10px;

  :focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
`;

export const HeaderBox = styled('div')`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CodeBlock = styled('div')`
  background: lightgray;
  font-size: 16px;
  font-family: monospace;
  font-weight: 500;
  padding: 5px;
`;

export const HeaderLogo = styled('img')`
  width: 75px;
`;

export const VeriSmartAIHeaderLogo = styled('img')`
  width: 150px;
`;

export const HeaderLogoBig = styled('img')`
  width: 100%;
`;

export const HeaderTitle = styled('label')`
  padding-top: 30px;
  font-size: 18px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const SubmitBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 330px;
  align-self: center;
`;

export const LogoutBlock = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-self: center;
`;

export const SubmitBtn = styled('button')`
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  width: 100px;
  height: 40px;
  border: none;
  display: block;
  color: #fff;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: 4px;
`;

export const ErrorMessage = styled('div')`
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 5px 10px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;