import * as GV1 from './generator.v1';
import * as GV2 from './generator.v2';
import * as falso from '@ngneat/falso';
import * as IV3 from '../interfaces/interfaces.v3';

export const bestCodesNewFormat: IV3.TAdaCode[] = GV1.bestCodes.map(
  (code) => `ADA:${code}` as IV3.TAdaCode,
);

type PartialV2Generator = new () => {
  [P in Exclude<
    keyof GV2.GeneratorV2,
    | 'genAlertNotes'
    | 'genTicket'
    | 'genPlan'
    | 'genBenefits'
    | 'genAdaCode'
    | 'genCorelatedWith'
    | 'genListOfHistory'
    | 'genHistory'
    | 'genPatient'
    | 'genOverallPlan'
    | 'genDetails'
  >]: GV2.GeneratorV2[P];
};
const PartialBaseClass: PartialV2Generator = GV2.GeneratorV2;

export class GeneratorV3 extends PartialBaseClass {
  static getInstance() {
    return new GeneratorV3();
  }

  constructor() {
    super();
  }

  genPatient(): IV3.IPatient {
    return {
      deductibleRemaining: falso.randNumber({ min: 0, max: 20 }) * 100,
      insuranceRemaining: falso.randNumber({ min: 0, max: 20 }) * 100,
      effectiveDate: falso.randSoonDate().toISOString(),
      note: falso.randText(),
      history: this.genListOfHistory(falso.randNumber({ min: 0, max: 20 })),
      unmappedHistory: this.genListOfUnmappedHistory(
        falso.randNumber({ min: 0, max: 20 }),
      ),
      coverageStatus: 'active',
      planEndDate: falso.randFutureDate().toISOString(),
    };
  }

  genListOfHistory(count: number): IV3.IHistory[] {
    const res: IV3.IHistory[] = [];
    for (let i = 0; i < count; i++) {
      res.push(
        this.genHistory(
          falso.rand([...GV1.historyWithArr]) as GV1.TGenHistoryWith,
        ),
      );
    }
    return res;
  }

  genListOfUnmappedHistory(count: number): IV3.TUnmappedHistory[] {
    const res: IV3.TUnmappedHistory[] = [];
    for (let i = 0; i < count; i++) {
      res.push(this.genUnmappedHistory());
    }
    return res;
  }

  genHistory(incl?: GV1.TGenHistoryWith): IV3.IHistory {
    const base = {
      date: falso.randSoonDate().toISOString(),
      code: falso.rand([...IV3.AdaCodeArray]),
      notes: falso.randText(),
    };

    const getRandSurf = () =>
      falso.rand(['M', 'D', 'L' /* TODO: improve surfaces by tooth nr */]);

    if (incl) {
      if (incl === 'tooth') {
        (base as IV3.IHistoryTeeth).tooth = [falso.rand([...IV3.ToothArray])];
      } else if (incl === 'surface') {
        const surfaceObj: { [key: IV3.TTooth]: IV3.TSurface } = {};
        surfaceObj[falso.rand([...IV3.ToothArray])] = getRandSurf();
        (base as IV3.IHistorySurfaces).surfaces = [surfaceObj];
      } else if (incl === 'quadrant') {
        (base as IV3.IHistoryQuadrants).quadrants = [
          falso.rand([...IV3.QuadrantArray]),
        ];
      } else if (incl === 'arch') {
        (base as IV3.IHistoryArch).arch = falso.rand([...IV3.ArchArray]);
      }
    }

    return base;
  }

  genUnmappedHistory(): IV3.TUnmappedHistory {
    return {
      date: falso.randSoonDate().toISOString(),
      originalBenefitKey: falso.randWord(),
      isWrongDate: falso.randBoolean(),
    };
  }

  genPlanNote(count: number) {
    const res: IV3.IPlanNote[] = [];
    for (let i = 0; i < count; i++) {
      res.push({
        creationDate: falso.randSoonDate().toISOString(),
        alertContent: falso.randText(),
        user: falso.randText(),
      });
    }

    return res;
  }

  genDetails(scored: boolean): IV3.IDetailsScored | IV3.IDetails {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    if (scored) {
      return {
        benefitYear: this.wrapScore(
          monthNames[falso.randFutureDate({ years: 2 }).getMonth()],
          true,
        ),
        planType: 'dental',
        participation: falso.rand(['In Network', 'Out of Network']),
        initiationDate: falso.randSoonDate().toISOString(),
      };
    } else {
      return {
        benefitYear: monthNames[falso.randFutureDate({ years: 2 }).getMonth()],
        planType: 'dental',
        participation: falso.rand(['In Network', 'Out of Network']),
        initiationDate: falso.randSoonDate().toISOString(),
        mappingInfo: [
          {
            message: falso.rand([...IV3.MappingInfoArray]),
            field: 'benefitYear',
          },
        ],
      };
    }
  }

  genPlanMetadata(): IV3.IPlanMetadata | undefined {
    return {
      payerSpecificPlanType: {
        matchedValue: this.getRandomEnumValue(IV3.PayerSpecificPlanType),
        originalValue: falso.rand([
          '- BLUE CROSS & BLUE SHIELD BASIC',
          'High Option Dental FEDVIP',
        ]),
      },
    };
  }

  genPlan(scored: boolean): IV3.ISchemaPlan {
    return {
      score: falso.randNumber({ min: 1, max: 10 }) as IV3.TPlanScore,
      details: this.genDetails(scored),
      payer: this.genPayerv3(),
      sponsor: this.genSponsor(),
      benefits: this.genBenefits(falso.randNumber({ min: 1, max: 2 }), scored),
      lastSubmission: new Date(falso.randSoonDate()).toISOString(),
      planNotes: this.genPlanNote(falso.randNumber({ min: 1, max: 5 })),
      planMetadata: this.genPlanMetadata(),
    };
  }

  genPayerv3(): IV3.IPayer {
    return {
      id: `${falso.randNumber({min: 10000, max: 99999})}`,
      name: falso.randCompanyName(),
      street1: falso.randStreetName(),
      street2: "",
      city: falso.randCity(),
      state: falso.randState(),
      zipCode: falso.randZipCode(),
      phoneNumber: falso.randPhoneNumber(),
      masterDBCarrierID: falso.randCompanyName().toUpperCase().replace(/\s/g, '_')
    }
  }

  genDraft(count: number): IV3.ISchemaPlan[] {
    const res: IV3.ISchemaPlan[] = [];
    for (let i = 0; i < count; i++) {
      res.push(this.genPlan(true));
    }

    return res;
  }

  genTicket(): IV3.ITicket {
    return {
      integration: falso.rand(['Freshdesk', 'V+', 'Zendesk']),
      externalId: `${falso.randNumber({ min: 1, max: 999 })}`,
      resolvedDate: new Date(falso.randSoonDate()).toISOString(),
      draft: this.genDraft(falso.randNumber({ min: 1, max: 3 })),
      verrificPlusInteralId: falso.randUuid(),
      clientSpecificPlanId: falso.rand(['', falso.randUuid()]),
      payerAgentName: `${falso.randFirstName()} ${falso.randLastName()}`,
      callReference: falso.randPhoneNumber(),
      documents: this.genListOfAttachments(
        falso.randNumber({ min: 1, max: 5 }),
      ),
    };
  }

  genTicketWrapper(scored: boolean): IV3.ITicketWrapper {
    return {
      patient: this.genPatient(),
      plan: this.genPlan(scored),
      ticket: this.genTicket(),
      schemaVersion: 'v3',
    };
  }

  genOverallPlan(scored: boolean): IV3.ISchemaAllPlan {
    return {
      patient: this.genPatient(),
      plan: this.genPlan(scored),
      ticket: this.genTicket(),
    };
  }

  genBenefits(
    codesCount: number,
    scored: boolean,
  ): IV3.IBreakdowns | IV3.IBreakdownsScored {
    const codes = falso.rand([...bestCodesNewFormat], {
      length: bestCodesNewFormat.length,
    });
    if (scored) {
      const res: IV3.IBreakdownsScored = {
        missingToothClause: this.wrapScore(falso.randBoolean(), scored),
        annualMax: this.wrapScore(
          falso.randNumber({ min: 1, max: 30 }) * 100,
          scored,
        ),
        deductible: {
          individual: this.wrapScore(
            falso.randNumber({ min: 1, max: 300 }) * 10,
            scored,
          ),
          family: this.wrapScore(
            falso.randNumber({ min: 1, max: 300 }) * 10,
            scored,
          ),
        },
        age: {
          child: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          student: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          dependent: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
        },
        adaCodes: {},
      };
      IV3.CategoryArray.forEach((category: unknown) => {
        res.adaCodes[category as IV3.TCategory] = this.genAdaObject(
          'category',
          scored,
          category as IV3.TCategory,
        ) as IV3.IAdaSimpleObjectScored;
      });
      IV3.SubcategoryArray.forEach((subcategory: unknown) => {
        res.adaCodes[subcategory as IV3.TSubcategory] = this.genAdaObject(
          'subcategory',
          scored,
          false,
          subcategory as IV3.TSubcategory,
        ) as IV3.IAdaSimpleObjectScored;
      });
      codes.forEach((code: unknown) => {
        res.adaCodes[code as IV3.TAdaCode] = this.genAdaObject(
          'adaCode',
          scored,
          false,
          false,
          code as IV3.TAdaCode,
        ) as IV3.IAdaSimpleObjectScored;
      });
      return res;
    } else {
      const res: IV3.IBreakdowns = {
        missingToothClause: falso.randBoolean(),
        annualMax: this.wrapScore(
          falso.randNumber({ min: 1, max: 30 }) * 100,
          scored,
        ),
        deductible: {
          individual: this.wrapScore(
            falso.randNumber({ min: 1, max: 300 }) * 10,
            scored,
          ),
          family: this.wrapScore(
            falso.randNumber({ min: 1, max: 300 }) * 10,
            scored,
          ),
        },
        age: {
          child: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          student: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          dependent: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
        },
        mappingInfo: [
          {
            message: falso.rand([...IV3.MappingInfoArray]),
            field: 'benefitLevel',
          },
        ],
        adaCodes: {},
      };
      IV3.CategoryArray.forEach((category: unknown) => {
        res.adaCodes[category as IV3.TCategory] = this.genAdaObject(
          'category',
          scored,
          category as IV3.TCategory,
        ) as IV3.IAdaSimpleObject;
      });
      IV3.SubcategoryArray.forEach((subcategory: unknown) => {
        res.adaCodes[subcategory as IV3.TSubcategory] = this.genAdaObject(
          'subcategory',
          scored,
          false,
          subcategory as IV3.TSubcategory,
        ) as IV3.IAdaSimpleObject;
      });
      codes.forEach((code: unknown) => {
        res.adaCodes[code as IV3.TAdaCode] = this.genAdaObject(
          'adaCode',
          scored,
          false,
          false,
          code as IV3.TAdaCode,
        ) as IV3.IAdaSimpleObject;
      });

      return res;
    }
  }

  genAdaObject(
    type: 'category' | 'subcategory' | 'adaCode',
    scored: boolean,
    category?: IV3.TCategory | false,
    subcategory?: IV3.TSubcategory | false,
    code?: IV3.TAdaCode,
  ): IV3.IAdaSimpleObject | IV3.IAdaSimpleObjectScored {
    const minAge = falso.randNumber({ min: 1, max: 40 });
    let corelated = falso.rand([...IV3.AdaCodeArray], {
      length: falso.randNumber({ min: 1, max: 10 }),
    });
    if (typeof corelated === 'string') {
      corelated = [corelated];
    }
    const excepted = falso.rand([...IV3.AdaCodeArray], {
      length: falso.randNumber({ min: 1, max: 10 }),
    });

    let adaObject: { [key: string]: any } = {};

    if (type === 'category') {
      adaObject = {
        ...adaObject,
        category,
      };
    }
    if (type === 'subcategory') {
      adaObject = {
        ...adaObject,
        category: falso.rand([...IV3.CategoryArray]),
        subcategory,
      };
    }
    if (type === 'adaCode') {
      adaObject = {
        ...adaObject,
        category: falso.rand([...IV3.CategoryArray]),
        subcategory: falso.rand([...IV3.SubcategoryArray]),
        code,
      };
    }

    adaObject = {
      ...adaObject,
      appliesToAnnualMax: this.wrapScore(falso.randBoolean(), scored),
      benefitLevel: this.wrapScore(
        falso.randNumber({ min: 0, max: 10 }) * 10,
        scored,
      ),
      limitation: this.wrapScore(
        {
          quantity: falso.randNumber({ min: 1, max: 12 }),
          frequency: falso.randNumber({ min: 1, max: 12 }),
          timePeriod: falso.rand(['mo', 'yr', '12m', '/yr']),
        },
        scored,
      ),
      age: {
        min: this.wrapScore(minAge, scored),
        max: this.wrapScore(
          falso.randNumber({ min: minAge + 1, max: 99 }),
          scored,
        ),
      },
      deductible: this.wrapScore(
        falso.randNumber({ min: 1, max: 20 }) * 10,
        scored,
      ),
      lifetimeMaximum: this.wrapScore(
        falso.randNumber({ min: 1, max: 900 }) * 10,
        scored,
      ),
      waitingPeriod: this.wrapScore(
        falso.rand([
          {
            months: falso.randNumber({ min: 1, max: 12 }),
            days: 0,
            years: 0,
          },
          {
            months: 0,
            days: falso.randNumber({ min: 1, max: 31 }),
            years: 0,
          },
          {
            months: 0,
            days: 0,
            years: falso.randNumber({ min: 1, max: 360 }),
          },
        ]),
        scored,
      ),
      correlatedWith: this.genCorelatedWith(corelated, scored),
      caveat: scored
        ? [this.wrapScore(falso.randText(), scored)]
        : [
            falso.randText({ length: falso.randNumber({ min: 0, max: 3 }) }),
          ].flat(),
    };

    if (scored) {
      return adaObject as IV3.IAdaSimpleObjectScored;
    } else {
      adaObject.mappingInfo = [
        {
          message: falso.rand([...IV3.MappingInfoArray]),
          field: 'benefitYear',
        },
      ];

      return adaObject as IV3.IAdaSimpleObject;
    }
  }

  genCorelatedWith(corelated: IV3.TAdaCode[], scored: boolean) {
    return corelated?.map((correlatedCode) => {
      const how = falso.rand(
        Object.values(IV3.CorrelationEnum).filter((item) => {
          return isNaN(Number(item));
        }),
      ) as IV3.CorrelationEnum;
      const res = {
        code: correlatedCode,
        how,
        apply: this.wrapScore(falso.randBoolean(), scored),
      };

      if (IV3.CorrelationEnum.WAITING_PERIOD === res.how) {
        (
          res as
            | IV3.ICorelatedWithWaitingPeriod
            | IV3.ICorelatedWithWaitingPeriodScored
        ).data = this.wrapScore(
          falso.rand([
            {
              months: falso.randNumber({ min: 1, max: 12 }),
              days: null,
              years: null,
            },
            {
              months: null,
              days: falso.randNumber({ min: 1, max: 31 }),
              years: null,
            },
            {
              months: null,
              days: null,
              years: falso.randNumber({ min: 1, max: 360 }),
            },
          ]),
          scored,
        );
      }

      if (IV3.CorrelationEnum.APPLIES_TO === res.how) {
        const teeth = [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
        ];
        let entities = falso.rand(teeth, {
          length: falso.randNumber({ min: 0, max: 10 }),
        });
        if (!Array.isArray(entities)) {
          entities = [entities];
        }
        (res as any).data = this.wrapScore([...entities], scored);
      }

      return res;
    });
  }

  getRandomEnumValue<T extends object>(enumObj: T): T[keyof T] {
    const values = Object.values(enumObj);
    const validValues = values.filter(
      (value) => typeof value === 'number' || typeof value === 'string',
    );
    const randomIndex = Math.floor(Math.random() * validValues.length);

    return validValues[randomIndex];
  }
}
