import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { StripeContextProps, StripeProviderProps } from './StripeContext.type';
import { isDOStripeIntegrated, isDOStripePaid } from './StripeContext.api';
import { useHistory, useLocation } from 'react-router-dom';
import { FrameLoading } from '../Components/FrameLoading';
import { Auth0Verrific } from 'verrific-plus-schema';
import { useAuth0 } from '@auth0/auth0-react';
import {getEnvs} from '../Utils/FrontEnvs';

const StripeContext = createContext<StripeContextProps | undefined>(
  undefined
);

export const StripeProvider: React.FC<StripeProviderProps> = ({
  children
}: StripeProviderProps) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [initialised, setInitialised] = useState(false);
  const [isStripeEnabled, setStripeEnabled] = useState(true);
  const [paid, setPaid] = useState<boolean | undefined>(undefined);
  const [integrated, setIntegrated] = useState<boolean | undefined>(undefined);


  const isIntegrated = async () => {
    if (integrated === false || integrated === undefined) {
      const integr = await isDOStripeIntegrated();
      setIntegrated(integr);
      return integr;
    } else {
      return integrated;
    }
  };

  const isPaid = async () => {
    if (paid === undefined || integrated === false) {
      const subscPaid = await isDOStripePaid();
      setPaid(subscPaid);
      return subscPaid;
    } else {
      return paid;
    }
  };

  const onRouteChange = async () => {
    if (isStripeEnabled) {
      if (Auth0Verrific.getToken()) {
        if (
          (Auth0Verrific.isDODuringRegistration() || 
          Auth0Verrific.isDentalOfficeAuthorized()) &&
          ['auto', 'locked', undefined].includes(Auth0Verrific.getPaymentType())
        ) {
          if (location.pathname.startsWith('/schedule') || location.pathname.startsWith('/verification')) {
            setLoading(true);
            if (!(await isIntegrated()) || !(await isPaid())) {
              history.push('/payment');
            }
            setLoading(false);
          }
        }
      } else {
        setTimeout(onRouteChange, 500);
      }
    }
  };

  useEffect(() => {
    getEnvs().then(envs => {
      if (envs) {
        setStripeEnabled(envs['stripe-payments'].isActive);
        setInitialised(true);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoading && initialised) {
      onRouteChange();
    }
  }, [location, initialised]);


  return (
    <StripeContext.Provider
      value={{
        isIntegrated,
        isPaid,
        isLoading,
        isStripeEnabled
      }}
    >
      <FrameLoading isLoading={isLoading} overAll backdrop={true}> 
        {children}
      </FrameLoading>
    </StripeContext.Provider>
  );
};

export const useStripeContext = (): StripeContextProps => {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripeContext must be used within a StripeProvider');
  }
  return context;
};
