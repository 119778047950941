import { mapValues, isObject, findKey, set, get } from 'lodash';
import { IScoredField } from '../interfaces/interfaces.v2';
import { Interface } from '../module';

/**
 * It checks if the checked object is verrificPlus scored field
 * Used by iterator to know what fields to merge or work upon
 * used for example by:
 * - stripping context
 * - merging plans
 * - mass decreasing scores
 *
 * Copied from packages/verrific_scoring_engine/src/ScoringEngine/Helpers/InternalHelpers.ts
 * @param item
 * @returns
 */
export function isScoredField(item: any): item is IScoredField<any> {
  return isObject(item) && typeof (item as any).value !== 'undefined';
  // as any - as TS compalin that object doesn't have this property
}

/**
 * to add context to current path, allowing fields to be correclty mapped in array
 * currently supports "correlatedWith" array
 *
 * used by:
 *
 *
 * Copied from packages/verrific_scoring_engine/src/ScoringEngine/Helpers/RecursiveIterator.ts
 */
function getObjectKey(value: any): string {
  if (typeof value.how === 'string' && typeof value.code === 'string') {
    return `*-${value.code}-${value.how}`;
  } else {
    return '*';
  }
}

/**
 * Iterates over object and triggers and callback on a plan scored field with value and calculated path
 *
 * Copied from packages/verrific_scoring_engine/src/ScoringEngine/Helpers/RecursiveIterator.ts
 * Based on https://stackoverflow.com/a/39087474/1675736
 */
export function recursiveScoredFieldMappingIterator(
  obj: any[] | object | Interface.ISchemaPlan | any,
  callback: (value: IScoredField<any>, path?: string) => void,
  path = '',
): any[] | object | any {
  if (!isObject(obj)) {
    return obj;
  }
  const handleField = (value: IScoredField<any> | object, key: string) => {
    if (!isObject(value)) {
      return value;
    }
    if (/^[0-9]+$/.test(key)) {
      // if key is numeric only then it's array so we should not track the array order but order by object keys
      key = getObjectKey(value);
    }
    const newKey = path + '.' + key;
    if (isScoredField(value)) {
      return callback(value, newKey);
    } else {
      return recursiveScoredFieldMappingIterator(value, callback, newKey);
    }
  };

  if (Array.isArray(obj)) {
    return obj.map((value, index) => handleField(value, String(index)));
  } else {
    return mapValues(obj, handleField);
  }
}
