import { Current } from '../interfaces';

export class Correlation implements Current.ICorelatedWithCommon {
  public data?: any;

  constructor(
    public code: Current.TAdaCode,
    public how: Current.CorrelationEnum,
    public apply: boolean = true,
  ) {}

  /**
   * Creates **ICorelatedWithCommon** object
   * * how: `SHARES_FREQUENCY`
   *
   * @param code
   * @param apply default `true`
   *
   * @returns ICorelatedWithCommon object
   */
  public static sharesFrequency(code: Current.TAdaCode, apply = true) {
    return new this(code, Current.CorrelationEnum.SHARES_FREQUENCY, apply);
  }

  /**
   * Creates **ICorelatedWithCommon** object
   * * how: `APPLIES_TO`
   *
   * @param code
   * @param apply default `true`
   *
   * @returns ICorelatedWithCommon object
   */
  public static appliesTo(code: Current.TAdaCode, apply = true) {
    return new this(code, Current.CorrelationEnum.APPLIES_TO, apply);
  }

  /**
   * Creates **ICorelatedWithCommon** object
   * * how: `SUBSTITUTION`
   *
   * @param code
   * @param apply default `true`
   *
   * @returns ICorelatedWithCommon object
   */
  public static substitution(code: Current.TAdaCode, apply = true) {
    return new this(code, Current.CorrelationEnum.SUBSTITUTION, apply);
  }

  /**
   * Creates **ICorelatedWithCommon** object
   * * how: `IN_CONJUNCTION`
   *
   * @param code
   * @param apply default `true`
   *
   * @returns ICorelatedWithCommon object
   */
  public static inConjunction(code: Current.TAdaCode, apply = true) {
    return new this(code, Current.CorrelationEnum.IN_CONJUNCTION, apply);
  }

  /**
   * Creates **ICorelatedWithCommon** object
   * * how: `WAITING_PERIOD`
   *
   * @param code
   * @param apply default `true`
   *
   * @returns ICorelatedWithCommon object
   */
  public static waitingPeriod(code: Current.TAdaCode, apply = true) {
    return new this(code, Current.CorrelationEnum.WAITING_PERIOD, apply);
  }
}
