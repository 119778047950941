import { isEqual } from 'lodash';
// import * as planRaw from './plan.json';

export const getType = (param: any) => {
  let res = Object.prototype.toString.call(param);
  res = res.split(' ')[1];
  res = res.replace(']', '');
  return res.toLowerCase();
};

export const compareArrays = (aParam: Array<any>, bParam: Array<any>) => {
  if (aParam.length !== bParam.length) {
    return false;
  }
  let bParamCopy = [...bParam];

  const res = aParam.reduce((acc: boolean, current: any, index: number) => {
    const indexB = bParamCopy.findIndex((el) => compare(current, el));
    if (indexB !== -1) {
      bParamCopy.splice(indexB, 1);
    }
    return acc && indexB !== -1;
  }, true);

  return res && bParamCopy.length === 0;
};

export const compare = (aParam: any, bParam: any): boolean => {
  const paramATypeComplex = getType(aParam);
  const paramBTypeComplex = getType(bParam);
  const paramATypeSimple = typeof aParam;
  const paramBTypeSimple = typeof bParam;

  if (
    paramATypeComplex === paramBTypeComplex &&
    paramATypeSimple === paramBTypeSimple
  ) {
    if (
      paramATypeSimple === 'object' &&
      paramATypeComplex !== 'null' &&
      paramATypeComplex !== 'array' &&
      paramATypeComplex !== 'date'
    ) {
      return compareObject(aParam, bParam);
    } else if (paramATypeComplex === 'array') {
      return compareArrays(aParam, bParam);
    } else if (paramATypeComplex === 'date') {
      return +aParam === +bParam;
    } else {
      return aParam === bParam;
    }
  } else {
    if (
      [paramATypeComplex, paramBTypeComplex].some((el) => el === 'number') &&
      [paramATypeComplex, paramBTypeComplex].some((el) => el === 'string') &&
      [aParam, bParam].some(
        (el) => getType(el) === 'string' && /^[0-9].*/.test(el)
      )
    ) {
      return Number(aParam) === Number(bParam);
    }
    return aParam === bParam;
  }
};

const compareObject = (
  objA: { [key: string]: any },
  objB: { [key: string]: any }
) => {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (!isEqual(keysA.sort(), keysB.sort())) {
    return false;
  }

  return keysA.reduce((acc: boolean, current: string) => {
    return acc && compare(objA[current], objB[current]);
  }, true);
};