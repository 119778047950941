import React, {
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import { RouteDefinition } from '../Routes/types';
import { AppRouterContextProps, AppRouterProviderProps } from './AppRouterContext.type';

const AppRouterContext = createContext<AppRouterContextProps | undefined>(
  undefined
);

export const AppRouterProvider: React.FC<AppRouterProviderProps> = ({
  children
}: AppRouterProviderProps) => {
  const [currentRoute, setCurrentRoute] = useState<RouteDefinition>();
  const [defPublic, setDefPublic] = useState<RouteDefinition>();
  const [defPrivate, setDefPrivate] = useState<RouteDefinition>();

  const getRoute = (): RouteDefinition | undefined => {
    return currentRoute;
  };

  const setRoute = (current: RouteDefinition) => {
    setCurrentRoute(current);
  };

  const getDefaultPublic = (): RouteDefinition | undefined => {
    return defPublic;
  };

  const setDefaultPublic = (pub: RouteDefinition): void => {
    setDefPublic(pub);
  };

  const getDefaultPrivate = (): RouteDefinition | undefined => {
    return defPrivate;
  };

  const setDefaultPrivate = (priv: RouteDefinition): void => {
    setDefPrivate(priv);
  };

  return (
    <AppRouterContext.Provider
      value={{
        getCurrentPageMeta: getRoute,
        setCurrentPageMeta: setRoute,
        getDefaultPublic,
        setDefaultPublic,
        getDefaultPrivate,
        setDefaultPrivate
      }}
    >
      {children}
    </AppRouterContext.Provider>
  );
};

export const useAppRouter = (): AppRouterContextProps => {
  const context = useContext(AppRouterContext);
  if (context === undefined) {
    throw new Error('useAppRouter must be used within a AppRouterProvider');
  }
  return context;
};
