import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  '@keyframes heartbeat': 
  {
    '0%':
    {
      transform: 'scale( 1 )',
    },
    '20%':
    {
      transform: 'scale( 1.1 )',
    },
    '30%':
    {
      transform:' scale( 1 )',
    },
    '40%':
    {
      transform: 'scale( 1.1 )',
    },
    '60%':
    {
      transform: 'scale( 1 )',
    },
    '100%':
    {
      transform: 'scale( 1 )',
    }
  },

  container: {
    position: 'relative'
  },
  loaderSpiner: {
    alignSelf: 'center'
  },
  backdrop: {
    background: '#ccccccaa',
  },
  backdropAlternate: {
    background: '#ffffff',
  },
  loaderRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: '16px',
    },
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    zIndex: 999
  },
  infoText: {
    position: 'absolute',
    bottom: 0,
  },
  splash: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    background: 'white',
    zIndex: 999999,
    transition: 'opacity 0.5s',
  },
  splashImg: {
    marginTop: 'calc(50vh - 141px)',
    width: '283px',
    height: '283px',
    transition: 'all 0.5s',
    animation: '$heartbeat 1000ms',
    animationIterationCount: 'infinite'
  }
});