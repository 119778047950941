import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  error: {
    background: '#FFDADA',
    color: '#FF3232',
    justifyContent: 'center',
    display: 'flex',
  },
  inprogress: {
    background: '#D3DDFF',
    color: '#0F44FF',
    justifyContent: 'center',
    display: 'flex',
  },
  saved: {
    background: '#D5EDDA',
    color: '#189A34',
    justifyContent: 'center',
    display: 'flex',
  },
  success: {
    background: '#D5EDDA',
    color: '#189A34',
    justifyContent: 'center',
    display: 'flex',
  },
  errorReport: {
    background: '#D5EDDA',
    color: '#189A34',
    justifyContent: 'center',
    display: 'flex',
  },
  copied: {
    background: '#D5EDDA',
    color: '#189A34',
    justifyContent: 'center',
    display: 'flex',
  },
  gridContainer: {maxWidth: '300px'},
  gridContainerWithError: {maxWidth: '100%'},
  iconPadding: {padding: '4px'}
});