import CommunicationService from './CommunicationService';

export interface IConnectionCredentials {
  domain: string;
  clientId: string;
}

export const mockLogin = async () => {
  const response = await CommunicationService.getInstance().publicCall<IConnectionCredentials>({
    type: 'GET',
    path: '/api/auth/credentials/auth0',
    forceHttp: true
  });
  if(response.error){
    console.log(response.error);
  }
  
  return response;
};

export const getConnectionData = async () => {
  const data = await (await mockLogin()).body;
  return data;
};