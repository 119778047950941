import { styled } from '@mui/system';

export const LogoImg = styled('img')`
  height: 56px;
  margin-top: 0px;
`;

export const MessageSpanCenter = styled('span')`
  width: 100%;
  text-align: center; 
  display: block;
`;

export const MessageSpanRight = styled('span')`
  width: 100%;
  text-align: right; 
  display: block;
`;
