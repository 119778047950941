import * as V1 from '../interfaces/interfaces.v1';
import * as V2 from '../interfaces/interfaces.v2';
import {
  isObject, ModifySchemaObjectsRecursively
} from '../utils/schema-object-modifier';

const getCurrentDate = (date?: number) => {
  const day = new Date(date || new Date()).getDate(); 
  const month = new Date(date || new Date()).getMonth()+1;
  const year = new Date(date || new Date()).getFullYear();

  return `${month < 10 ? '0'+month : month }/${day < 10 ? '0'+day : day }/${year}`;
};

export const upTicket = (ticket: V1.ISchemaAllPlan): V2.ISchemaAllPlan => {
  const newTicketSchema: V2.ISchemaAllPlan = ticket as any;


  // Replace effectiveDate timestamp with string date
  if (!!newTicketSchema.patient?.effectiveDate) {
    newTicketSchema.patient.effectiveDate =
      getCurrentDate(Number(newTicketSchema.patient.effectiveDate));
  }

  // Replace history date timestamp with string date
  if (!!newTicketSchema.patient?.history) {
    newTicketSchema.patient?.history.forEach((historyRecord) => {
      if (!!historyRecord.date) {
        historyRecord.date =
          getCurrentDate(Number(historyRecord.date));
      }
    })
  }

  // Replace alert note creationDate timestamp with string date
  if (!!newTicketSchema.ticket?.alertNotes) {
    newTicketSchema.ticket?.alertNotes.forEach((alertRecord) => {
      if (!!alertRecord.creationDate) {
        alertRecord.creationDate =
          new Date(Number(alertRecord.creationDate)).toISOString();
      }
    })
  }

  // Replace resolveDate timestamp with string date
  if (!!newTicketSchema.ticket?.resolvedDate) {
    newTicketSchema.ticket.resolvedDate =
      new Date(Number(newTicketSchema.ticket.resolvedDate)).toISOString();
  }

  // Migrate up plan to V2
  if (!!newTicketSchema.plan) {
    if (Array.isArray(newTicketSchema.plan)) {
      newTicketSchema.plan = newTicketSchema.plan.map((singlePlan) => {
        return up(singlePlan as V1.ISchemaPlan);
      }) as V2.ISchemaPlan[]
    } else {
      newTicketSchema.plan = up(ticket.plan as V1.ISchemaPlan);
    }
  }

  // Migrate up ticket draft to V2
  if (!!newTicketSchema.ticket?.draft) {
    if (Array.isArray(newTicketSchema.ticket.draft)) {
      newTicketSchema.ticket.draft =
        newTicketSchema.ticket.draft.map((singlePlan) => {
          return up(singlePlan as V1.ISchemaPlan);
        }) as V2.ISchemaPlan[]
    } else {
      newTicketSchema.ticket.draft =
        up(ticket.ticket!.draft as V1.ISchemaPlan);
    }
  }

  if (!!newTicketSchema.ticket) {
    newTicketSchema.ticket.schemaVersion = '2';
  }

  newTicketSchema.schemaVersion = '2';

  return newTicketSchema;
}

export const up = (schema: V1.ISchemaPlan): V2.ISchemaPlan => {
  // Replace timestamp in changeHistory with updatedAt
  const newSchema: V2.ISchemaPlan = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.timestamp) {
        field.updatedAt = new Date(Number(field.timestamp)).toISOString();
        delete field.timestamp;
      }

      return field;
    },
    (item: any) => {
      return isObject(item) && item.timestamp !== undefined;
    }
  );

  // Replace initiationDate timestamp with string date
  if (!!schema.details?.initiationDate) {
    newSchema.details.initiationDate =
      new Date(Number(schema.details?.initiationDate)).toISOString();
  }

  if (!!newSchema.benefits?.adaCodes) {
    Object.values(newSchema.benefits?.adaCodes).forEach((adaCode) => {
      // Replace coinsurance with benefitLevel
      if (adaCode.coinsurance) {
        adaCode.benefitLevel = adaCode.coinsurance;
        delete adaCode.coinsurance;
      }
      if (adaCode.exceptions) {
        Object.values(adaCode.exceptions).forEach((exception: any) => {
          // Replace coinsurance with benefitLevel in exception
          if (exception.coinsurance) {
            exception.benefitLevel = exception.coinsurance;
            delete exception.coinsurance;
          }

          // Change age object in exception
          if (exception.age) {
            exception.age = upAge(exception.age);
          }
        })
      }
      if (adaCode.conflicts) {
        adaCode.conflicts.forEach((conflict: any) => {
          // Replace benefitLevel with coinsurance in conflict
          if (conflict.coinsurance) {
            conflict.benefitLevel = conflict.coinsurance;
            delete conflict.coinsurance;
          }

          // Change age object in exception
          if (conflict.age) {
            conflict.age = upAge(conflict.age);
          }
        })
      }

      // Change age object
      if (adaCode.age) {
        adaCode.age = upAge(adaCode.age);
      }
    })
  }

  // Use string date instead of timestamp number
  if (!!schema.lastSubmition) {
    newSchema.lastSubmission =
      new Date(Number(schema.lastSubmition)).toISOString();
    delete (newSchema as any).lastSubmition;
  }

  newSchema.schemaVersion = '2';

  return newSchema;
}

export const downTicket = (ticket: V2.ISchemaAllPlan): V1.ISchemaAllPlan => {
  const oldTicketSchema: V1.ISchemaAllPlan = ticket as any;

  if (!!oldTicketSchema.patient?.effectiveDate) {
    oldTicketSchema.patient.effectiveDate =
      new Date(oldTicketSchema.patient.effectiveDate).getTime();
  }

  if (!!oldTicketSchema.patient?.history) {
    oldTicketSchema.patient?.history.forEach((historyRecord) => {
      if (!!historyRecord.date) {
        historyRecord.date =
          new Date(historyRecord.date).getTime();
      }
    })
  }

  if (!!oldTicketSchema.ticket?.alertNotes) {
    oldTicketSchema.ticket?.alertNotes.forEach((alertRecord) => {
      if (!!alertRecord.creationDate) {
        alertRecord.creationDate =
          new Date(alertRecord.creationDate).getTime();
      }
    })
  }

  if (!!oldTicketSchema.ticket?.resolvedDate) {
    oldTicketSchema.ticket.resolvedDate =
      new Date(oldTicketSchema.ticket.resolvedDate).getTime();
  }

  if (!!oldTicketSchema.plan) {
    if (Array.isArray(oldTicketSchema.plan)) {
      oldTicketSchema.plan = oldTicketSchema.plan.map((singlePlan) => {
        return down(singlePlan as V2.ISchemaPlan);
      }) as V1.ISchemaPlan[]
    } else {
      oldTicketSchema.plan = down(ticket.plan as V2.ISchemaPlan);
    }  
  }

  if (!!oldTicketSchema.ticket?.draft) {
    if (Array.isArray(oldTicketSchema.ticket.draft)) {
      oldTicketSchema.ticket.draft = down(
        oldTicketSchema.ticket.draft?.[0] as V2.ISchemaPlan
      );
    } else {
      oldTicketSchema.ticket.draft =
        down(ticket.ticket!.draft as V2.ISchemaPlan);
    }
  }

  if (!!oldTicketSchema.ticket) {
    oldTicketSchema.ticket.schemaVersion = '1';
  }

  oldTicketSchema.schemaVersion = '1';

  return oldTicketSchema;
}

export const down = (schema: V2.ISchemaPlan): V1.ISchemaPlan => {
  // Replace updatedAt in changeHistory with timestamp
  const oldSchema: V1.ISchemaPlan = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.updatedAt) {
        field.timestamp = new Date(String(field.updatedAt)).getTime();
        delete field.updatedAt;
      }

      return field;
    },
    (item: any) => {
      return isObject(item) && item.updatedAt !== undefined;
    }
  );

  // Replace initiationDate string date with timestamp
  if (!!schema.details?.initiationDate) {
    oldSchema.details.initiationDate =
      new Date(String(schema.details?.initiationDate)).getTime();
  }

  if (!!oldSchema.benefits?.adaCodes) {
    Object.values(oldSchema.benefits?.adaCodes).forEach((adaCode) => {
      // Replace benefitLevel with coinsurance
      if (adaCode.benefitLevel) {
        adaCode.coinsurance = adaCode.benefitLevel;
        delete adaCode.benefitLevel;
      }

      if (adaCode.exceptions) {
        Object.values(adaCode.exceptions).forEach((exception: any) => {
          // Replace benefitLevel with coinsurance in exception
          if (exception.benefitLevel) {
            exception.coinsurance = exception.benefitLevel;
            delete exception.benefitLevel;
          }

          // Change age object in exception
          if (exception.age) {
            exception.age = downAge(exception.age);
          }
        })
      }

      if (adaCode.conflicts) {
        adaCode.conflicts.forEach((conflict: any) => {
          // Replace benefitLevel with coinsurance in conflict
          if (conflict.benefitLevel) {
            conflict.coinsurance = conflict.benefitLevel;
            delete conflict.benefitLevel;
          }

          // Change age object in conflict
          if (conflict.age) {
            conflict.age = downAge(conflict.age);
          }
        })
      }

      // Change age object
      if (adaCode.age) {
        adaCode.age = downAge(adaCode.age);
      }
    })
  }

  // Use timestamp number instead of string date
  if (!!schema.lastSubmission) {
    oldSchema.lastSubmition = new Date(String(schema.lastSubmission)).getTime();
    delete (oldSchema as any).lastSubmission;
  }

  oldSchema.schemaVersion = '1';

  return oldSchema;
}

const upAge = (age: any) => {
  let newAge = {};
  if (age?.value?.min) {
    newAge = {
      min: {
        value: Number(age.value.min),
        score: age.score
      }
    }
  }
  if (age?.value?.max) {
    newAge = {
      ...newAge,
      max: {
        value: Number(age.value.max),
        score: age.score
      }
    }
  }

  return newAge;
};

const downAge = (age: any) => {
  let oldAge = {};
  if (age?.min || age?.max) {
    oldAge = {
      value: {
        min: Number(age.min?.value) || undefined,
        max: Number(age.max?.value) || undefined
      },
    score: Math.min(age.min?.score || age.max?.score)
  }
}
 
  return oldAge;
}