import {
  AdaCodeArray,
  SponsorTypeArray,
  ToothArray,
  QuadrantArray,
  ArchArray, 
  RelationToSubscriberEnum,
  TicketStatusEnum,
  TQuadrant,
  CorrelationEnum,
  TTooth,
  AnteriorTeeth,
  PosterioiTeeth,
  TTimePeriodArray
} from '../interfaces/interfaces.v2';
import * as yup from 'yup';

yup.addMethod(yup.object, 'atLeastOneRequired', function(list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => value == null || list.some((f: string) => value[f] != null)
  })
});
yup.addMethod(yup.string, 'integer', function (value) {
  return this.matches(/^\d+$/, `${this.__inputType} The field should have digits only`);
})
export class ValidatorV2 {
  private static instance: ValidatorV2;
  public static getInstance() {
    if (!ValidatorV2.instance) {
      ValidatorV2.instance = new ValidatorV2();
    }

    return ValidatorV2.instance;
  }

  constructor() {
    AdaCodeArray.forEach(code => {
      if (!this.objForAdaShapes[code] && code !== 'NONE') {
        this.objForAdaShapes[code] = this.VAdaObject(true).default(undefined);
      }
    });
    AnteriorTeeth.forEach(teeth => {
      if(!this.objForSurfaces[teeth]){
        this.objForSurfaces[teeth] =  yup.string().matches(/^(?!.*?(.).*?\1)[MDLIF]+$/);
      }
    });
    PosterioiTeeth.forEach(teeth => {
      if(!this.objForSurfaces[teeth]){
        this.objForSurfaces[teeth] = yup.string().matches(/^(?!.*?(.).*?\1)[MDLOB]+$/);
      }
    })
  }

  public objForAdaShapes: { [key: string]: any} = {};
  public objForSurfaces: { [key: TTooth]: any } = {};
  
  VFieldHistory = yup.object({
    userHash: yup.string().required(),
    updatedAt: yup.string().strict().required(),
    value: yup.mixed().required()
  }).noUnknown();

  VLimitation = yup.object({
    quantity: yup.number().positive().required(),
    frequency: yup.number().positive().required(),
    timePeriod: yup.string().oneOf([...TTimePeriodArray]).required()
  });

  VAge = yup.object({
    min: yup.number().min(0).max(359).nullable(),
    max: yup.number().max(360).nullable().when(
      'min',
      (min, schema) => (min && !isNaN(min) ? schema.min(min) : schema.min(0)),
    ),
  }).noUnknown().strict();

  VWaitingPeriod = (yup.object({
    months: yup.number().max(9999).min(0).nullable(true).default(undefined),
    days: yup.number().max(36000).min(0).nullable(true).default(undefined),
    weeks: yup.number().max(5400).min(0).nullable(true).default(undefined),
    years: yup.number().max(9999).min(0).nullable(true).default(undefined)
  }) as any).atLeastOneRequired(['months','days','years', 'weeks']);

  VCorrelatedWithData = yup.object({
    value:  yup.lazy(val => Array.isArray(val) ? 
      yup.array().of(yup.string()) : this.VWaitingPeriod.default(undefined))
  });

  VScoredValueString = yup.object({
    value: yup.string(),
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredValueNumber = yup.object({
    value: yup.number(),
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredValueBoolean = yup.object({
    value: yup.boolean(),
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredValueLimitation = yup.object({
    value: this.VLimitation,
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredValueAge = yup.object({
    min: yup.object({
      value: yup.number().integer().min(0).max(359),
      score: yup.number().integer().positive().min(0).max(3),
      changeHistory: yup.array().of(this.VFieldHistory)
    }),
    max: yup.object({
      value: yup.number().integer().min(0),
      score: yup.number().integer().positive().min(0).max(3),
      changeHistory: yup.array().of(this.VFieldHistory)
    }),
  }).noUnknown().strict();

  VScoredValueWaitingPeriod = yup.object({
    value: this.VWaitingPeriod,
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredArrayOrString = yup.object({
    value: yup.mixed(),
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredCorrelatedWithData = yup.object({
    value: yup.lazy(val => Array.isArray(val) ? 
    yup.array().of(yup.string()) : this.VWaitingPeriod.default(undefined)),
    score: yup.number().integer().positive().min(0).max(3),
    changeHistory: yup.array().of(this.VFieldHistory)
  });

  VScoredOrNot = (type: 'string' | 'number' | 'boolean' | 'limitation' | 'age' | 'waitingPeriod' | 'arrayOrString' | 'correlatedWithData', scored?: boolean) => {
    switch (type) {
      case 'string':
        return scored ? this.VScoredValueString : yup.string();
      case 'number':
        return scored ? this.VScoredValueNumber : yup.number();
      case 'boolean':
        return scored ? this.VScoredValueBoolean : yup.boolean();
      case 'limitation':
        return scored ? this.VScoredValueLimitation : this.VLimitation;
      case 'age':
        return scored ? this.VScoredValueAge : this.VAge;
      case 'waitingPeriod':
        return scored ? this.VScoredValueWaitingPeriod : this.VWaitingPeriod;
      case 'arrayOrString':
        return scored ? this.VScoredArrayOrString : yup.mixed();
      case 'correlatedWithData':
        return scored ? this.VScoredCorrelatedWithData : this.VCorrelatedWithData;
    }
  }

  VBreakdownAge(scored?: boolean) {
    return yup.object({
      child: this.VScoredOrNot('age', scored).default(undefined),
      student: this.VScoredOrNot('age', scored).default(undefined),
      dependent: this.VScoredOrNot('age', scored).default(undefined)
    });
  }

  VAttachmets(scored?: boolean) {
    return yup.array().of(yup.string().url());
  }

  VSponsor(scored?: boolean){
    return yup.object({
      sponsorName: yup.string(),
      sponsorType: yup.string().oneOf([...SponsorTypeArray]),
      groupName: yup.string(),
      groupNumber: yup.string()
    });
  } 

  VPayer(scored?: boolean) {
    return yup.object({
      id: yup.string(),
      name: yup.string(),
      street1: yup.string(),
      street2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zipCode: yup.string(),
      phoneNumber: yup.string(),
    });
  }

  VHistoryRecord(scored?: boolean) {
    return yup.object({
      code: yup.string().oneOf([...AdaCodeArray]),
      date: yup.string().strict().required(),
      notes: yup.string(),
      tooth: yup.array().of(yup.string().oneOf(ToothArray)),
      surfaces: yup.array().of(yup.object().shape(this.objForSurfaces)),
      quadrants: yup.array().of(yup.mixed<TQuadrant>().oneOf(QuadrantArray)),
      arch: yup.string().oneOf(ArchArray),
      toothRange: yup.array().of(yup.mixed<TTooth>().oneOf(ToothArray)),
    }); 
  }

  VPatient(scored?: boolean) {
    const patient = yup.object({
      deductibleRemaining: yup.number(),
      insuranceRemaining: yup.number(),
      effectiveDate: yup.string().strict(),
      note: yup.string(),
      history: yup.array().of(this.VHistoryRecord(scored))
    });
    return patient;
  }

  VAdaObjectCustomExtended(scored?: boolean): any {
    return yup.object().shape({
      appliesToAnnualMax:  this.VScoredOrNot('boolean', scored),
      benefitLevel:  this.VScoredOrNot('number', scored),
      deductible:  this.VScoredOrNot('number', scored),
      waitingPeriod:  this.VScoredOrNot('waitingPeriod', scored).default(undefined),
      lifetimeMaximum:  this.VScoredOrNot('number', scored),
      limitation:  this.VScoredOrNot('limitation', scored).default(undefined),
      caveat: yup.array().of(yup.string()),
      correlatedWith: yup.array().of(yup.object({
        code: yup.mixed().oneOf(AdaCodeArray as any),
        how: yup.mixed().oneOf(Object.values(CorrelationEnum)),
        apply: yup.boolean(),
        data:  this.VScoredOrNot('arrayOrString', scored),
      })),
      age:  this.VScoredOrNot('age', scored),
    });
  }

  VAdaObject(scored?: boolean): any {
    return yup.object().shape({
      code: yup.mixed().oneOf(AdaCodeArray as any).required(),
      category: yup.string().required(),
      wholeCategory: this.VScoredOrNot('boolean', scored),
      appliesToAnnualMax: this.VScoredOrNot('boolean', scored),
      benefitLevel: this.VScoredOrNot('number', scored),
      deductible: this.VScoredOrNot('number', scored),
      waitingPeriod: this.VScoredOrNot('waitingPeriod', scored).default(undefined),
      lifetimeMaximum: this.VScoredOrNot('number', scored),
      limitation: this.VScoredOrNot('limitation', scored).default(undefined),
      caveat: yup.array().of(yup.string()),
      correlatedWith: yup.array().of(yup.object({
        code: yup.mixed().oneOf(AdaCodeArray as any),
        how: yup.mixed().oneOf(Object.values(CorrelationEnum)),
        apply: this.VScoredOrNot('boolean', scored),
        data: this.VScoredOrNot('arrayOrString', scored),
      })),
      exceptions: yup.object({
        child: this.VAdaObjectCustomExtended(scored),
        dependent: this.VAdaObjectCustomExtended(scored)
      }).default(undefined),
      age:  this.VScoredOrNot('age', scored),
    });
  } 

  VBreakdown(scored?: boolean) {
    return yup.object({
      missingToothClause: this.VScoredOrNot('boolean', scored),
      annualMax: this.VScoredOrNot('number', scored),
      deductible: yup.object({
        individual: this.VScoredOrNot('number', scored),
        family: this.VScoredOrNot('number', scored),
      }),
      age: this.VBreakdownAge(scored),
      adaCodes:  yup.object().shape(this.objForAdaShapes),
    });
  } 

  VDetails(scored?: boolean) {
    return yup.object({
      benefitYear: this.VScoredOrNot('string', scored),
      participation: yup.string().strict(),
      initiationDate: yup.string().strict().nullable(true),
    });
  }

  VPlan(scored?: boolean) {
    const plan = yup.object({
      benefits: this.VBreakdown(scored).required(),
      payer: this.VPayer(scored).required(),
      sponsor: this.VSponsor(scored).required(),
      score: yup.number().nullable(true),
      details: this.VDetails(scored).required(),
      userHashes: yup.array().of(yup.string()),
      planHash: yup.string(),
      lastSubmission: yup.string().strict(),
      schemaVersion: yup.string(),
    });
    return plan;
  }

  VAlertNotes(scored?: boolean) {
    return yup.object({
      creationDate: yup.string().strict(),
      alertContent: yup.string().required(),
      user: yup.string().required()
    });
  }

  VClientInfo =  yup.object({
    name: yup.string(),
    primaryDoctorName: yup.string(),
    taxId: yup.string(),
    phone: yup.string(),
    npi: yup.string(),
    address: yup.string(),
    maleAgent: yup.string(),
    femaleAgent: yup.string()
  });

  VPatientInfo =  yup.object({
    patientFirstName: yup.string().required(),
    patientLastName: yup.string().required(),
    patientDOB: yup.string().required(),
    patientMemberId: yup.string().required(),
    patientAddress: yup.string(),
    patientRelationToSubscriber: yup.mixed().oneOf(Object.values(RelationToSubscriberEnum)).required(),
    subscriberFirstName: yup.string(),
    subscriberLastName: yup.string(),
    subscriberDOB: yup.string(),
    subscriberMemberId: yup.string()
  });

  VTicket(scored?: boolean): any {
    const ticket = yup.object({
      integrationPatientInfo: this.VPatientInfo.default(undefined),
      integrationClientInfo: this.VClientInfo.default(undefined),
      resolvedDate: yup.string().strict(),
      draft: this.VPlan(scored).default(undefined),
      submitPlanId: yup.string(),
      status: yup.mixed().oneOf(Object.values(TicketStatusEnum)),
      verrificPlusInteralId: yup.string().required(),
      clientSpecificPlanId: yup.string(),
      payerAgentName: yup.string(),
      callReference: yup.string(),
      alertNotes: yup.array().of(this.VAlertNotes(scored)),
      documents: this.VAttachmets(scored),
      externalUrl: yup.string(),
      integration: yup.string(),
      externalId: yup.string(),
      ticketHash: yup.string(),
      schemaVersion: yup.string(),
      isDirty: yup.boolean(),
      requesterId: yup.string(),
      imported: yup.boolean(), 
      ehr: yup.string(),
      ehrVersion: yup.string(),
      pluginName: yup.string(),
      pluginVersion: yup.string(),
      pluginEnvironment: yup.string(),
      disabledFields: yup.array().of(yup.string())
    });
    return ticket;
  }

  VTicketWrapper(scored?: boolean) {
    const wrapper = yup.object({
      patient: this.VPatient(scored).required(),
      plan: this.VPlan(scored).default(undefined).nullable(true),
      ticket: this.VTicket(scored).default(undefined),
      schemaVersion: yup.string()
    });
    return wrapper;
  }
}
