import { Interface } from '../module';
export interface AddressInterface {
  street1: string;
  street2?: string;
  city?: string;
  zip?: string;
  state?: string;
}

export type CoverageStatusEnum =
  | 'active'
  | 'terminated'
  | 'inactive'
  | 'unknown';

export interface CoverageInterface {
  effective_date: string;
  status: CoverageStatusEnum;
  end_date?: string;
}

export interface MetadataInterface {
  description?: string; // onderful one api doesn't response with the documentation
  qualifier?: string; // onderful one api doesn't response with the documentation
  /**
   * probably interchangeable with qualifier
   */
  type?: string;
  value: string;
}

export interface PatientInterface {
  name: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  dob: string;
  member_id: string;
  address: AddressInterface;
  gender?: string;
  coverage: CoverageInterface;
  metadata?: MetadataInterface[];
}

export interface ProviderInterface {
  first_name?: string;
  last_name?: string;
  organization_name?: string;
  tax_id?: string;
  npi?: string;
}

export interface PlanInterface {
  name: string;
  number?: string;
  type?: string;
  group_number: string;
  group_name?: string;
  state?: string;
  metadata?: MetadataInterface[];
}

export interface PayerInterface {
  id: string;
  name: string;
  phone?: string;
  claim_address?: AddressInterface;
}

export const PlanPeriodEnumArray = [
  'year_to_date',
  'calendar',
  'contract',
  'service',
  'remaining',
  'lifetime',
  'lifetime_remaining',
  'unknown',
] as const;
export type PlanPeriodEnum = typeof PlanPeriodEnumArray[number];

export const CoverageLevelEnumArray = [
  'family',
  'individual',
  'children_only',
  'dependents_only',
  'employee_and_children',
  'employee_only',
  'employee_and_spouse',
  'spouse_and_children',
  'spouse_only',
  'unknown',
] as const;
export type CoverageLevelEnum = typeof CoverageLevelEnumArray[number];

export interface ServiceHistory {
  procedure_code?: string;
  service_date?: string;
  tooth_code?: string;
  tooth_surfaces?: string;
  type?: string;
  value?: string;
}

export interface HealthServiceDescription {
  quantity_qualifier?: string;
  quantity?: string;
  unit_qualifier?: string;
  unit?: string;
  time_period_qualifier?: string;
  time_period_value?: string;
  delivery_frequency?: string;
  delivery_pattern_time?: string;
}

export interface AgeLimit {
  age_high_value: string;
  age_low_value: string;
}

export const BenefitInterfaceKnownKeysArray = [
  'network',
  'category',
  'insurance_type',
  'service_type',
  'plan_period',
  'amount',
  'coverage_level',
  'percent',
  'procedure_code',
  'limitation',
  'limitation_applies_to',
  'service_dates',
  'services_remaining',
  'disclaimers',
  'quantity',
  'quantity_qualifier',
  'health_service_descriptions',
  'payer_specific_description',
  'age_limit',
] as const;

export type BenefitInterfaceKnownKeys =
  typeof BenefitInterfaceKnownKeysArray[number];

export interface BenefitInterface {
  /** Normalized procedure_code to our internal format */
  _code?: Interface.TAdaCode;
  _codes?: Interface.TAdaCode[];
  _type?: string;
  network?: string; //BenefitAllowedNetworkEnum;
  category?: string;
  insurance_type?: string;
  service_type?: string; //BenefitAllowedServiceTypeEnum;
  plan_period?: PlanPeriodEnum;
  amount?: string;
  coverage_level?: CoverageLevelEnum;
  percent?: string;
  procedure_code?: string;
  limitation?: string;
  limitation_applies_to?: string[];
  service_dates?: ServiceHistory[];
  services_remaining?: number;
  disclaimers?: string[];
  quantity?: string;
  quantity_qualifier?: string;
  health_service_descriptions?: HealthServiceDescription[];
  payer_specific_description?: string;
  age_limit?: AgeLimit;
}

export interface BaseStructure {
  onederfulId?: string;
  subscriber: PatientInterface;
  dependents?: PatientInterface[];
  patient: PatientInterface;
  provider?: ProviderInterface;
  plan: PlanInterface;
  payer?: PayerInterface;
  active_coverage?: BenefitInterface[];
  deductible?: BenefitInterface[];
  maximums?: BenefitInterface[];
  coinsurance?: BenefitInterface[];
  waiting_period?: BenefitInterface[];
  limitations?: BenefitInterface[];
  not_covered?: BenefitInterface[];
  payer_specific_info?: BenefitInterface[];
  copayment?: BenefitInterface[];
  out_of_pocket?: BenefitInterface[];
  disclaimers?: string[];
  payer_uuid?: string;

  api_count?: number;
}

const payorIDs = [
  '3M',
  'CDMN1',
  '07000',
  '32_BJ',
  '11198',
  'AARP',
  'AARP1',
  'ADMINISTRATIVE_SERVICES_ONLY',
  'CX076',
  'ADVANTICA',
  '43168',
  'AETNA_DENTAL_PLANS',
  '57604',
  '60054',
  'AFFINITY_HEALTH',
  'CX014',
  'AFLAC_GA',
  '58066',
  'AFLAC_NY',
  '52080',
  'ALIERA',
  'ALH01',
  'ALLEGIANCE',
  '81040',
  'TLU11',
  'ALTUS',
  '50503',
  'AMERIGROUP_GA',
  'CX014',
  'AMERIGROUP_WA_MEDICARE',
  'CX014',
  'AMERITAS',
  '47009',
  'AMERITAS_NEW_YORK',
  '72630',
  '60033',
  'ANTHEM',
  '84105',
  'ANTHEM_BCBS_CALIFORNIA',
  '47198',
  '84103',
  'ANTHEM_BCBS_COLORADO',
  '84099',
  'AD050',
  'ANTHEM_BCBS_CONNECTICUT',
  '700',
  'AD060',
  'ANTHEM_BCBS_GEORGIA',
  'CBGA1',
  'SB601',
  'ANTHEM_BCBS_INDIANA',
  '84105',
  'AD130',
  'ANTHEM_BCBS_KENTUCKY',
  '84105',
  'AD160',
  'ANTHEM_BCBS_MAINE',
  '84105',
  'BME01',
  'AD180',
  'ANTHEM_BCBS_NEVADA',
  '84101',
  'AD265',
  'ANTHEM_BCBS_NEW_HAMPSHIRE',
  '84105',
  'BNH01',
  'AD270',
  'ANTHEM_BCBS_OHIO',
  '84105',
  'AD332',
  'ANTHEM_BCBS_VIRGINIA',
  'CB923',
  '84103',
  'AD423',
  'ANTHEM_BCBS_WISCONSIN_FEP',
  'CB950',
  '39141',
  'AD450',
  'ASURIS',
  '93221',
  'BCBS_ALABAMA',
  'CBAL1',
  'BCBS_ALASKA',
  '47570',
  'BCBS_ARIZONA',
  '53589',
  'TBX01',
  'BCBS_ARKANSAS',
  'CBAR1',
  '83470',
  'BCBS_DE',
  '53287',
  'BCBS_ILLINOIS',
  'CB621',
  '621',
  '00621',
  'BCBS_KANSAS',
  'CBKS1',
  '47163',
  'BCBS_KANSAS_CITY_MO',
  '47171',
  'DX067',
  'BCBS_LOUISIANA',
  'UCCAP',
  'BCBS_MARYLAND',
  '00580',
  'BCBS_MASSACHUSETTS',
  'CBMA1',
  'BCBS_MICHIGAN',
  'BBMDQ',
  'BCBS_MO',
  '84105',
  'TLX52',
  'AD241',
  'BCBS_MONTANA',
  'CBMT1',
  'TLU06',
  'BCBS_ND',
  'CX004',
  'BCBS_NEW_MEXICO',
  'CBNM1',
  '84100',
  'BCBS_OKLAHOMA',
  'SB840',
  '840',
  'BCSOK',
  'BCBS_OREGON',
  'CB850',
  '851',
  '00851',
  'BCBS_RHODE_ISLAND',
  'CB870',
  '53473',
  'BCBS_SOUTH_CAROLINA',
  '38520',
  'BCSSC',
  'BCBS_SOUTH_CAROLINA_FEP',
  'DXSCF',
  'BCBS_TENNESSEE',
  'CBTN1',
  'BCBS_TEXAS',
  'CB900',
  '84980',
  'BCBS_WASHINGTON',
  '47570',
  'BCBS_WASHINGTON_DC',
  '00580',
  'BCBS_WISCONSIN',
  'CB950',
  '39141',
  'AD450',
  'BCBS_WY',
  'CBWY1',
  'TLX12',
  '53767',
  'BENEFIT_INC',
  'R7003',
  'BLUE_ADVANTAGE',
  'CBAR1',
  '83470',
  'TLY26',
  'BLUE_CARE',
  '700',
  'AD060',
  'BLUE_SHIELD_CALIFORNIA',
  '94036',
  '52133',
  'BLUE_SHIELD_CALIFORNIA_FEDERAL',
  'BSCA2',
  'BLUE_SHIELD_IDAHO',
  'CBID2',
  '611',
  '00611',
  'BLUE_SHIELD_PENNSYLVANNIA',
  'CB865',
  '0000D',
  'UCCDP',
  'BLUE_SHIELD_WASHINGTON',
  '93200',
  '932',
  '00932',
  'CIGNA',
  '62308',
  'CIGNA_INTERNATIONAL',
  'CIGN1',
  'CIGNA_SELECT',
  '63665',
  '80705',
  'COMMUNITY_UNITED_HEALTHCARE',
  'GP133',
  'COMPBENEFITS_AND_HUMANA_SPECIALTY_BENEFITS',
  'CX021',
  'CONNECTICUT_GENERAL_CIGNA',
  '62308',
  'COUNCIL_CARPENTERS_WELFARE_FUND',
  'CDIN1',
  'DELTI',
  'DDPI',
  'DD_ALABAMA',
  '94276',
  'DDAL1',
  'DD_AR',
  'CDAR1',
  'DELAR',
  'DDPAR',
  'DD_AZ',
  '86027',
  'DD_CALIFORNIA',
  '77777',
  'DD_COLORADO',
  '84056',
  'DDPCO',
  'DD_CONNECTICUT',
  '22189',
  'DD_DELAWARE',
  '51022',
  'DD_FLORIDA',
  '94276',
  'DDFL1',
  'DD_GEORGIA',
  '94276',
  'DDGA1',
  'DD_ID',
  '82029',
  'DD_ILLINOIS_GROUP_PLANS',
  '05030',
  '47598',
  'DD_INDIANA',
  'CDIN1',
  'DELTI',
  'DDPI',
  'DD_IOWA',
  'CDIA1',
  'DD_IOWA_GOVERNMENT',
  'CDIAM',
  'DD_KS',
  'CDKS1',
  'DD_KY',
  'CDKY1',
  'DD_LOUISIANA',
  '94276',
  'DDLA1',
  'DD_MA',
  '04614',
  'DD_MARYLAND',
  '23166',
  'DDMD1',
  'DD_MICHIGAN',
  'CDMI0',
  'DELTA',
  'DDPM',
  'DD_MINNESOTA',
  'CDMN1',
  '07000',
  'DD_MISSISSIPPI',
  '94276',
  'DDMS1',
  'DD_MISSOURI',
  '43090',
  'DD_MONTANA',
  '94276',
  'DDMT1',
  'DD_NEBRASKA',
  'CDNE1',
  '7027',
  '07027',
  'DD_NEVADA',
  '94276',
  'DDNV1',
  'DD_NEW_JERSEY',
  '22189',
  'DD_NEW_MEXICO',
  '85022',
  'DELTM',
  'DDPNM',
  'DD_NEW_YORK',
  '11198',
  'DD_NORTH_CAROLINA',
  '56101',
  'DD_NORTH_DAKOTA',
  'CDND1',
  'CDMN1',
  '07029',
  'DD_NORTHEAST',
  '2027',
  '02027',
  'DD_OHIO',
  'CDOH1',
  'DELTO',
  'DDPO',
  'DD_OKLAHOMA',
  'CDOK1',
  'DELOK',
  'DDPOK',
  'DD_PENNSYLVANIA',
  '23166',
  'DD_PUERTO_RICO',
  '66043',
  'DD_RHODE_ISLAND',
  '5029',
  '05029',
  'DD_TENNESSEE',
  'CDTN1',
  'DELTN',
  'DDPTN',
  'DD_TEXAS',
  '94276',
  'DDTX1',
  'DD_TRICARE',
  'CDCA1',
  'DDPFS',
  'DD_UTAH',
  '94276',
  'DDUT1',
  'DD_VIRGINIA',
  'CDVA1',
  '54084',
  'DD_WASHINGTON',
  '91062',
  'DD_WASHINGTON_DC',
  '52147',
  'DD_WEST_VIRGINIA',
  '31096',
  'DD_WI',
  '39069',
  'DDIC',
  '94276',
  'DELTA_DENTAL_SOUTH_CAROLINA',
  '43091',
  'DELTACARE_CLAIMS',
  'DDCA2',
  'CDCA2',
  'DELTACARE_USA_ENCOUNTERS',
  'DDCA3',
  'CDCAP',
  'DENTAL_GUARD_PREFERRED',
  '64246',
  'DENTAL_NETWORK_MARYLAND',
  'CX034',
  '00580',
  'DENTAQUEST_GOVERNMENT',
  'CX014',
  'DENTICAL',
  '94146',
  'DENTIST_DIRECT',
  'UTUDD',
  'DESERET_MUTUAL_BENEFIT_ADMINISTRATORS',
  'CX089',
  'TFQ56',
  'DX214',
  'DIRECT_DENTAL',
  'SDCOM',
  'EVERENCE',
  '35605',
  'FIRST_RELIANCE_STANDARD',
  '13317',
  '71005',
  'FLEX_COMPENSATION',
  'R7004',
  'FLORIDA_COMBINED_LIFE',
  'CBFLU',
  '76031',
  'GEHA',
  '44054',
  'GOLDEN_WEST',
  'GWD01',
  'GROUP_DENTAL_SERVICE',
  'CX036',
  'GUARDIAN',
  '64246',
  'HAWAII_DENTAL_SERVICE',
  'DEHI1',
  '99010',
  'HDS_MEDICAID',
  'TMCHI',
  'HDSMH',
  'HEALTH_CHOICE_AZ',
  '62179',
  'HEALTHCHOICE_OKLAHOMA',
  '71064',
  'HEALTHPLEX',
  '11271',
  'HEALTHSCOPE',
  '71063',
  'HEALTHSCOPE_MORRIS',
  '35092',
  'TLU78',
  'HEALTHSCOPE_TRIBUTE',
  '31118',
  'HEALTHSOURCE_PROVIDENT',
  '62308',
  'HERSHEY_HEALTHSMILE',
  'CX013',
  '0000D',
  'HMSA',
  'HMSA1',
  'HUMANA',
  '73288',
  'HUMANA_COMPBENEFITS_FEDERAL_PLAN_DENTAL',
  'CX021',
  'HUMANA_SPECIALTY_BENEFITS_FLORIDA_HEALTHY_KIDS_DENTAL',
  'CX021',
  'HUMANA_SPECIALTY_BENEFITS_STATE_FLORIDA_DENTAL',
  'CX021',
  'INTERNATIONAL_BROTHERHOOD_ELECTRICAL_WORKERS',
  'CDOH1',
  'DELTO',
  'DDPO',
  'KAISER',
  'CX073',
  'LINCOLN_FINANCIAL_GROUP',
  'CX061',
  'JPF01',
  'MANAGED_DENTALGUARD',
  'GI813',
  'MCNA',
  '65030',
  'MEDICA',
  'CX026',
  '07031',
  'MEDICAID_ALABAMA',
  'CKAL1',
  'MEDICAID_AR',
  'CKAR1',
  'MEDICAID_COLORADO',
  'CKCO1',
  'CX014',
  'MEDICAID_CONNECTICUT',
  'CKCT1',
  'MEDICAID_DENTAQUEST',
  'CX014',
  'MEDICAID_FL',
  'CKFL1',
  'MEDICAID_GEORGIA',
  'CKGA1',
  'CKGA2',
  'MEDICAID_IA',
  'CKIA1',
  'MEDICAID_IDAHO',
  'CKID1',
  'CX014',
  'MEDICAID_ILLINOIS',
  'CKIL1',
  'CX014',
  'MEDICAID_INDIANA',
  'CKIN1',
  'MEDICAID_KENTUKY_DORAL_DENTAL',
  'CKKY3',
  'CX014',
  'MEDICAID_KENTUKY_HEALTH_CHOICE',
  'CKKY1',
  'TLU10',
  'MEDICAID_KS',
  'CKKS1',
  'MEDICAID_MA',
  'CKMA1',
  'MEDICAID_MARYLAND',
  'CKMD1',
  'CX014',
  'MEDICAID_ME',
  'CKME1',
  'MEDICAID_MICHIGAN',
  'CKMI1',
  'MEDICAID_MINNESOTA',
  'CKMN1',
  'MEDICAID_MO',
  'CKMO1',
  'MEDICAID_MS',
  'CKMS1',
  'MEDICAID_MT',
  'CKMT1',
  'MEDICAID_NC',
  'CKNC1',
  'MEDICAID_ND',
  'CKND1',
  'MEDICAID_NEW_YORK',
  'CKNY1',
  'MEDICAID_NH',
  'CKNH1',
  'MEDICAID_NJ',
  'CKNJ1',
  'MEDICAID_NM',
  'CKNM1',
  'MEDICAID_NV',
  'CKNV1',
  'MCNV1',
  'MEDICAID_OH',
  'CKOH1',
  'MEDICAID_OK',
  'CKOK1',
  'MEDICAID_OR',
  'CKOR1',
  'MEDICAID_PA',
  'CKPA1',
  'MEDICAID_SOUTH_CAROLINA',
  'CKSC1',
  'CX014',
  'MEDICAID_TENNESSEE_BLUECARE',
  'CX014',
  'MEDICAID_TEXAS_DENTAQUEST',
  'CX014',
  'MEDICAID_TX',
  'CKTX1',
  'MEDICAID_UTAH',
  'CKUT1',
  'MEDICAID_VIRGINIA',
  'CKVA1',
  'CX014',
  'MEDICAID_VIRGINIA_HEALTHKEEPERS',
  'CX014',
  'MEDICAID_VT',
  'CKVT1',
  'MEDICAID_WASHINGTON',
  'CKWA1',
  '77045',
  'MEDICAID_WI',
  'CKWI1',
  'MEDICAID_WISCONSIN_DENTAQUEST',
  'CX014',
  'MEDICAID_WISCONSIN_HUMANA_TDC',
  'CX014',
  'MEDICAID_WISCONSIN_PRIMECARE',
  'CX014',
  'MEDICAID_WV',
  'CKWV1',
  'MEDICAID_WY',
  'CKWY1',
  'MERCY_CARE',
  '86052',
  'CX014',
  'METLIFE',
  '65978',
  '0000E',
  'MINNESOTA_BLUE_CROSS_DENTAL_(UCCI)',
  'UCCMB',
  'MMO',
  '29076',
  'MOLINA',
  'SKYGN',
  'MUTUAL_HEALTH_SERVICES',
  '34192',
  'MVP_SELECT_CARE',
  '14165',
  'TLX30',
  'NDDS',
  'CX004',
  'NIPPON',
  '81264',
  'PACIFICARE',
  'CX053',
  '52133',
  'PERSONAL_CHOICE',
  'CX007',
  '0000D',
  'UCCDP',
  'PHOENIX_HEALTH_DENTAQUEST',
  'CX014',
  'PHYSICIANS_MUTUAL',
  'CX068',
  'PRIMECARE_WISCONSIN',
  'CX014',
  'PRIMEWEST',
  'LX049',
  'PRINCIPAL',
  '61271',
  'PUBLIC_EMPLOYEE_HEALTH_PROGRAM',
  'CX080',
  'TFQ65',
  'REGENCE_BCBS_OREGON_OUT',
  'CB850',
  '851',
  '00851',
  'RELIANCE_STANDARD_LIFE',
  '36088',
  'SELECTHEALTH',
  'CX107',
  'TPC17',
  'SKYGEN',
  'SKYGN',
  'STANDARD_INSURANCE',
  '93024',
  '69019',
  'STANDARD_INSURANCE_NEW_YORK',
  '13411',
  '89009',
  'TRICARE',
  '89070',
  'CX002',
  'TRICARE_ACTIVE_RESERVISTS',
  'DXTAS',
  'TRICARE_RETIREE_DENTAL_PROGRAM',
  'CDCA1',
  'DDPFS',
  'TRIGON_BCBS_COLORADO',
  '47198',
  '84103',
  'TRIGON_BCBS_VIRGINIA',
  '47198',
  '84103',
  'TRUSTMARK',
  '61425',
  'UFT_WELFARE_FUND',
  '62308',
  'UMR',
  '79480',
  'UMR_HARRINGTON',
  '95266',
  '39026',
  'UMR_LEXINGTON',
  '37237',
  'UMR_SAN_ANTONIO',
  '74223',
  '39026',
  'UMR_WAUSAU',
  '39026',
  'UNICARE',
  '80314',
  '80314D',
  'UNITED_CONCORDIA',
  'CX007',
  '0000D',
  '89070',
  'UNITED_CONCORDIA_ENCOUNTERS',
  'UCCEN',
  'UNITED_CONCORDIA_FEDERAL_EMPLOYEES_PROGRAM',
  'CX007',
  '0000D',
  '54771',
  'UNITED_CONCORDIA_PA_BLUE_SHIELD',
  'CB865',
  'UNITED_CONCORDIA_TRICARE_DENTAL_PLAN',
  'UCCTR',
  'UNITED_HEALTHCARE',
  '37060',
  '52133',
  'UNITED_MEDICAL_RESOURCES',
  '33108',
  '39026',
  'WILSON_MCSHANE_CORP',
  'R7002',
  'ZENITH',
  'R7001',
  'TLY21',
] as const;

export type TPayorID = typeof payorIDs[number];
