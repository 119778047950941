import * as falso from '@ngneat/falso';
import { 
  ISponsor,
  IPayer,
  IDetails,
  ISchemaPlan,
  IHistoryQuadrants,
  IHistory,
  IPatient,
  TScore,
  CorrelationEnum,
  CategoryArray,
  AdaCodeArray,
  TAdaCode,
  IBreakdowns,
  IBreakdownsScored,
  IAdaSimpleObjectScored,
  IAdaSimpleObject,
  ISchemaAllPlan,
  ToothArray,
  SurfacePostteriorArray,
  SurfaceAnteriorArray,
  QuadrantArray,
  IHistoryArch,
  ArchArray,
  TPlanScore,
  IAlertNotes,
  ICorelatedWithWaitingPeriod,
  ICorelatedWithWaitingPeriodScored,
  IHistoryTeeth,
  IHistorySurfaces,
  TTooth,
  TSurface,
  ITicket,
  IFieldHistoryEntry
} from '../interfaces/interfaces.v1';

export const historyWithArr = ['tooth', 'surface', 'quadrant', 'arch', undefined];
export type TGenHistoryWith = 'tooth' | 'surface' | 'quadrant' | 'arch';
export const bestCodes: TAdaCode[] = [
  'D1208',
  'D0120',
  'D0210',
  'D2391',
  'D3310',
  'D1351',
  'D4341',
  'D7210',
  'D2740',
  'D4355',
  'D4381',
  'D4910',
  'D4266',
  'D7953', 
  'D7210', 
  'D6010',
  'D6057',
  'D8090',
  'D6058',
  'D9110'
];

export class GeneratorV1 {
  static getInstance() {
    return new GeneratorV1();
  }

  constructor() {}

  genHistoryLabel() {
    const quantity = falso.randNumber({min: 0, max: 10});
    const res: any[] = [];
    for (let i = 0; i<quantity; i++) {
      res.push({
        label: falso.randWord(),
        value: falso.randWord(),
      })
    }
    return res;
  }

  genHistorySurface() {
    const quantity = falso.randNumber({min: 0, max: 10});
    const res: any[] = [];
    for (let i = 0; i<quantity; i++) {
      res.push({
        id: falso.randUuid(),
        data: [falso.randWord()],
      })
    }
    return res;
  }

  genHistoryString() {
    const quantity = falso.randNumber({min: 0, max: 10});
    const res: any[] = [];
    for (let i = 0; i<quantity; i++) {
      res.push(falso.randWord())
    }
    return res;
  }

  genHistory(incl?: TGenHistoryWith): IHistory {
    const base = {
      date: +falso.randSoonDate(),
      code: falso.rand([...AdaCodeArray]),
      notes: falso.randText()
    };

    const getRandSurf = () => falso.rand([ 'M', 'D', 'L', /* TODO: improve surfaces by tooth nr */ ]);

    if (incl) {
      if (incl === 'tooth') {
        (base as IHistoryTeeth).tooth = [falso.rand([...ToothArray])]
      } else if (incl === 'surface') {
        const surfaceObj: { [key: TTooth]: TSurface } = {};
        surfaceObj[falso.rand([...ToothArray])] = getRandSurf();
        (base as IHistorySurfaces).surfaces = [surfaceObj];
      } else if (incl === 'quadrant') {
        (base as IHistoryQuadrants).quadrants = [falso.rand([...QuadrantArray])];
      } else if (incl === 'arch') {
        (base as IHistoryArch).arch = falso.rand([...ArchArray]);
      }
    }

    return base;
  }

  genListOfHistory(count: number): IHistory[] {
    const res: IHistory[] = [];
    for (let i = 0; i < count; i++) {
      res.push(this.genHistory(falso.rand([...historyWithArr]) as TGenHistoryWith));
    }
    return res;
  };

  genPatient(): IPatient {
    return {
      deductibleRemaining: falso.randNumber({min: 0, max: 20}) * 100,
      insuranceRemaining: falso.randNumber({min: 0, max: 20}) * 100,
      effectiveDate: +falso.randSoonDate(),
      note: falso.randText(),
      history: this.genListOfHistory(falso.randNumber({min: 0, max: 20})),
    }
  }

  genCustomQuestion() {
    return {
      question: `${falso.rand([...AdaCodeArray])} ${falso.randText()}?`,
      value: falso.rand(['yes', 'no', 'maybe']),
      type: 'string'
    }
  }

  genListOfCustomQuestions(count: number) {
    const res: any[] = [];
    for (let i = 0; i < count; i++) {
      res.push(this.genCustomQuestion());
    }
  };

  genDetails(): IDetails {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return {
      benefitYear: this.wrapScore(monthNames[falso.randFutureDate({years: 2}).getMonth()], true),
      participation: falso.rand(['In Network', 'Out of Network']),
      initiationDate: +falso.randSoonDate(),
    }
  }

  genPayer(): IPayer {
    return {
      id: `${falso.randNumber({min: 10000, max: 99999})}`,
      name: falso.randCompanyName(),
      street1: falso.randStreetName(),
      street2: "",
      city: falso.randCity(),
      state: falso.randState(),
      zipCode: falso.randZipCode(),
      phoneNumber: falso.randPhoneNumber()
    }
  }

  genSponsor(): ISponsor {
    return {
      sponsorName: falso.randCompanyName(),
      sponsorType: falso.rand(['Individual', 'Employer', 'Government']),
      groupNumber: `${falso.randNumber({min: 10000, max: 99999})}`,
      groupName: falso.randCompanyName()
    }
  }

  genListOfAttachments(count: number) {
    const res: any[] = [];
    for (let i = 0; i < count; i++) {
      res.push(falso.randUrl());
    }
    return res;
  }

  genAlertNotes(count: number) {
    const res: IAlertNotes[] = [];
    for (let i = 0; i < count; i++) {
      res.push({
        creationDate: +falso.randSoonDate(),
        alertContent: falso.randText(),
        user: falso.randText()
      });
    }
    return res;
  }

  genFieldHistory(count: number) {
    const res: IFieldHistoryEntry<string>[] = [];
    for (let i = 0; i < count; i++) {
      res.push({
        userHash: falso.randUuid(),
        timestamp: +falso.randSoonDate(),
        value: falso.randText()
      });
    }
    return res;
  }

  wrapScore(value: any, scored: boolean) {
    if (scored) {
      return {
        value,
        score: falso.randNumber({ min: 1, max: 3 }) as TScore,
        changeHistory: this.genFieldHistory(falso.randNumber({min: 1, max: 5}))
      }
    }
    return value;
  }

  genAdaCode(code: TAdaCode, scored: boolean): any {
    const minAge = falso.randNumber({min: 1, max: 40})
    let corelated = falso.rand([...AdaCodeArray], {length: falso.randNumber({min: 1, max: 10})});
    if (typeof corelated === 'string') {
      corelated = [corelated];
    }
    const excepted = falso.rand([...AdaCodeArray], { length: falso.randNumber({ min: 1, max: 10 }) });
    
    return {
      code,
      category: falso.rand([...CategoryArray]),
      appliesToAnnualMax: this.wrapScore(falso.randBoolean(), scored),
      coinsurance: this.wrapScore(falso.randNumber({min: 0, max: 10}) * 10, scored),
      limitation: this.wrapScore({
        quantity: falso.randNumber({min: 1, max: 12}),
        frequency: falso.randNumber({min: 1, max: 12}),
        timePeriod: falso.rand(['mo', 'yr', '12m', '/yr' ]),
      }, scored),
      age: this.wrapScore({
        min: minAge,
        max: falso.randNumber({min: minAge + 1, max: 99})
      }, scored),
      wholeCategory: this.wrapScore(falso.randBoolean(), scored),
      deductible: this.wrapScore(falso.randNumber({min: 1, max: 20}) * 10, scored),
      lifetimeMaximum: this.wrapScore(falso.randNumber({min: 1, max: 900}) * 10, scored),
      waitingPeriod: this.wrapScore(falso.rand([{
        months: falso.randNumber({min: 1, max: 12}),
        days: 0,
        years: 0 
      }, {
        months: 0,
        days: falso.randNumber({min: 1, max: 31}),
        years: 0
      }, {
        months: 0,
        days: 0,
        years: falso.randNumber({min: 1, max: 360}),
      }]), scored),
      correlatedWith: this.genCorelatedWith(corelated, scored),
      caveat: [falso.randText({length: falso.randNumber({min: 0, max: 3})})].flat(),
    }
  }

  genCorelatedWith(corelated: TAdaCode[], scored: boolean) {
    return corelated?.map(correlatedCode => {
      const how = falso.rand(Object.values(CorrelationEnum).filter((item) => {
        return isNaN(Number(item));
      })) as CorrelationEnum;
      const res = {
        code: correlatedCode,
        how,
        apply: this.wrapScore(falso.randBoolean(), scored)
      }

      if (CorrelationEnum.WAITING_PERIOD === res.how) {
        (res as ICorelatedWithWaitingPeriod | ICorelatedWithWaitingPeriodScored).data = this.wrapScore(falso.rand([{
          months: falso.randNumber({min: 1, max: 12}),
          days: null,
          years: null
        }, {
          months: null,
          days: falso.randNumber({min: 1, max: 31}),
          years: null
        }, {
          months: null,
          days: null,
          years: falso.randNumber({min: 1, max: 360}),
        }]), scored)
      }

      if (CorrelationEnum.APPLIES_TO === res.how) {
        const teeth = [
          '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16', '17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J','K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'
        ];
        let entities = falso.rand(teeth, {length: falso.randNumber({min: 0, max: 10})});
        if (!Array.isArray(entities)) {
          entities = [entities];
        }
        (res as any).data = this.wrapScore([...entities], scored);
      }

      return res;
    });
  }

  genBenefits(codesCount: number, scored: boolean): IBreakdowns | IBreakdownsScored {
    const codes = falso.rand([...bestCodes], {length: bestCodes.length});
    if (scored) {
      const res: IBreakdownsScored = {
        missingToothClause: this.wrapScore(falso.randBoolean(), scored),
        annualMax: this.wrapScore(falso.randNumber({min: 1, max: 30}) * 100, scored),
        deductible: {
          individual: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
          family: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
        },
        adaCodes: {}
      };
      codes.forEach((code: unknown) => {
        res.adaCodes[code as TAdaCode] = this.genAdaCode(code as TAdaCode, true);
      });
      return res;
    } else {
      const res: IBreakdowns = {
        missingToothClause: falso.randBoolean(),
        annualMax: this.wrapScore(falso.randNumber({min: 1, max: 30}) * 100, scored),
        deductible: {
          individual: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
          family: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
        },
        adaCodes: {}
      };
      codes.forEach((code: unknown) => {
        res.adaCodes[code as TAdaCode] = this.genAdaCode(code as TAdaCode, false);
      });
      return res;
    }
  }

  genPlan(scored: boolean): ISchemaPlan {
    return ({
      score: falso.randNumber({min: 1, max: 10}) as TPlanScore,
      details: this.genDetails(),
      payer: this.genPayer(),
      sponsor: this.genSponsor(),
      benefits: this.genBenefits(falso.randNumber({ min: 1, max: 2 }), scored),
      lastSubmition: +falso.randSoonDate()
    })
  } 

  genTicket(): ITicket {
    return ({
      integration: falso.rand(['Freshdesk', 'V+', 'Zendesk']),
      externalId: `${falso.randNumber({ min: 1, max: 999 })}`,
      resolvedDate: +falso.randSoonDate(),
      draft: this.genPlan(true),
      verrificPlusInteralId: falso.randUuid(),
      clientSpecificPlanId: falso.rand(['', falso.randUuid()]),
      payerAgentName: `${falso.randFirstName()} ${falso.randLastName()}`,
      callReference: falso.randPhoneNumber(),
      documents: this.genListOfAttachments(falso.randNumber({min: 1, max: 5})),
      alertNotes: this.genAlertNotes(falso.randNumber({min: 1, max: 5}))
    })
  } 

  genOverallPlan(scored: boolean): ISchemaAllPlan {
    return ({
      patient: this.genPatient(),
      plan: this.genPlan(scored),
      ticket: this.genTicket()
    })
  }
}