import * as GV1 from './generator.v1';
import * as falso from '@ngneat/falso';
import * as IV2 from '../interfaces/interfaces.v2';
import * as IV1 from '../interfaces/interfaces.v1';

type PartialV1Generator = new() => { [P in Exclude<keyof GV1.GeneratorV1, 'genTicket'
  | 'genPlan'
  | 'genAdaCode'
  | 'genDetails'
  | 'genBenefits'
  | 'genFieldHistory'
  | 'genOverallPlan'
  | 'genListOfHistory'
  | 'genPatient'
  | 'genHistory'
  | 'genAlertNotes'
  | 'wrapScore'
>]: GV1.GeneratorV1[P] };
const PartialBaseClass: PartialV1Generator = GV1.GeneratorV1;

export class GeneratorV2 extends PartialBaseClass {
  static getInstance() {
    return new GeneratorV2();
  }

  constructor() {
    super();
  }

  genPatient(): IV2.IPatient {
    return {
      deductibleRemaining: falso.randNumber({min: 0, max: 20}) * 100,
      insuranceRemaining: falso.randNumber({min: 0, max: 20}) * 100,
      effectiveDate: falso.randSoonDate().toISOString(),
      note: falso.randText(),
      history: this.genListOfHistory(falso.randNumber({min: 0, max: 20})),
    }
  }

  genListOfHistory(count: number): IV2.IHistory[] {
    const res: IV2.IHistory[] = [];
    for (let i = 0; i < count; i++) {
      res.push(this.genHistory(falso.rand([...GV1.historyWithArr]) as GV1.TGenHistoryWith));
    }
    return res;
  };

  genHistory(incl?: GV1.TGenHistoryWith): IV2.IHistory {
    const base = {
      date: falso.randSoonDate().toISOString(),
      code: falso.rand([...IV1.AdaCodeArray]),
      notes: falso.randText()
    };

    const getRandSurf = () => falso.rand([ 'M', 'D', 'L', /* TODO: improve surfaces by tooth nr */ ]);

    if (incl) {
      if (incl === 'tooth') {
        (base as IV2.IHistoryTeeth).tooth = [falso.rand([...IV1.ToothArray])]
      } else if (incl === 'surface') {
        const surfaceObj: { [key: IV1.TTooth]: IV1.TSurface } = {};
        surfaceObj[falso.rand([...IV1.ToothArray])] = getRandSurf();
        (base as IV2.IHistorySurfaces).surfaces = [surfaceObj];
      } else if (incl === 'quadrant') {
        (base as IV2.IHistoryQuadrants).quadrants = [falso.rand([...IV1.QuadrantArray])];
      } else if (incl === 'arch') {
        (base as IV2.IHistoryArch).arch = falso.rand([...IV1.ArchArray]);
      }
    }

    return base;
  }

  genFieldHistory(count: number): IV2.IFieldHistoryEntry<string>[] {
    const res: any[] = [];
    for (let i = 0; i < count; i++) {
      res.push({
        userHash: falso.randUuid(),
        updatedAt: falso.randSoonDate().toISOString(),
        value: falso.randText()
      });
    }
    return res;
  }

  wrapScore(value: any, scored: boolean) {
    if (scored) {
      return {
        value,
        score: falso.randNumber({ min: 1, max: 3 }) as IV2.TScore,
        changeHistory: this.genFieldHistory(falso.randNumber({min: 1, max: 5}))
      }
    }
    return value;
  }

  genAlertNotes(count: number) {
    const res: IV2.IAlertNotes[] = [];
    for (let i = 0; i < count; i++) {
      res.push({
        creationDate: falso.randSoonDate().toISOString(),
        alertContent: falso.randText(),
        user: falso.randText()
      });
    }
    return res;
  }

  genTicket(): IV2.ITicket {
    return ({
      integration: falso.rand(['Freshdesk', 'V+', 'Zendesk']),
      externalId: `${falso.randNumber({ min: 1, max: 999 })}`,
      resolvedDate: new Date(falso.randSoonDate()).toISOString(),
      draft: this.genPlan(true),
      verrificPlusInteralId: falso.randUuid(),
      clientSpecificPlanId: falso.rand(['', falso.randUuid()]),
      payerAgentName: `${falso.randFirstName()} ${falso.randLastName()}`,
      callReference: falso.randPhoneNumber(),
      documents: this.genListOfAttachments(falso.randNumber({min: 1, max: 5})),
      alertNotes: this.genAlertNotes(falso.randNumber({min: 1, max: 5}))
    })
  }

  genPlan(scored: boolean): IV2.ISchemaPlan {
    return ({
      score: falso.randNumber({min: 1, max: 10}) as IV2.TPlanScore,
      details: this.genDetails(),
      payer: this.genPayer(),
      sponsor: this.genSponsor(),
      benefits: this.genBenefits(falso.randNumber({ min: 1, max: 2 }), scored),
      lastSubmission: new Date(falso.randSoonDate()).toISOString()
    })
  }

    genBenefits(codesCount: number, scored: boolean): IV2.IBreakdowns | IV2.IBreakdownsScored {
    const codes = falso.rand([...GV1.bestCodes], {length: GV1.bestCodes.length});
    if (scored) {
      const res: IV2.IBreakdownsScored = {
        missingToothClause: this.wrapScore(falso.randBoolean(), scored),
        annualMax: this.wrapScore(falso.randNumber({min: 1, max: 30}) * 100, scored),
        deductible: {
          individual: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
          family: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
        },
        age: {
          child: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          student: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          dependent: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          }
        },
        adaCodes: {}
      };
      codes.forEach((code: unknown) => {
        res.adaCodes[code as IV2.TAdaCode] = this.genAdaCode(code as IV2.TAdaCode, true) as IV2.IAdaSimpleObjectScored;
      });
      return res;
    } else {
      const res: IV2.IBreakdowns = {
        missingToothClause: falso.randBoolean(),
        annualMax: this.wrapScore(falso.randNumber({min: 1, max: 30}) * 100, scored),
        deductible: {
          individual: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
          family: this.wrapScore(falso.randNumber({min: 1, max: 300}) * 10, scored),
        },
        age: {
          child: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          student: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          },
          dependent: {
            max: this.wrapScore(falso.randNumber({ min: 1, max: 30 }), scored),
          }
        },
        adaCodes: {}
      };
      codes.forEach((code: unknown) => {
        res.adaCodes[code as IV2.TAdaCode] = this.genAdaCode(code as IV2.TAdaCode, false) as IV2.IAdaSimpleObject;
      });
      return res;
    }
  }

  genDetails(): IV2.IDetailsScored {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return {
      benefitYear: this.wrapScore(monthNames[falso.randFutureDate({years: 2}).getMonth()], true),
      participation: falso.rand(['In Network', 'Out of Network']),
      initiationDate: falso.randSoonDate().toISOString(),
    }
  }

  genAdaCode(code: IV2.TAdaCode, scored: boolean): IV2.IAdaSimpleObject | IV2.IAdaSimpleObjectScored {
    const minAge = falso.randNumber({min: 1, max: 40})
    let corelated = falso.rand([...IV2.AdaCodeArray], {length: falso.randNumber({min: 1, max: 10})});
    if (typeof corelated === 'string') {
      corelated = [corelated];
    }
    const excepted = falso.rand([...IV2.AdaCodeArray], { length: falso.randNumber({ min: 1, max: 10 }) });
    
    return {
      code,
      category: falso.rand([...IV2.CategoryArray]),
      appliesToAnnualMax: this.wrapScore(falso.randBoolean(), scored),
      benefitLevel: this.wrapScore(falso.randNumber({min: 0, max: 10}) * 10, scored),
      limitation: this.wrapScore({
        quantity: falso.randNumber({min: 1, max: 12}),
        frequency: falso.randNumber({min: 1, max: 12}),
        timePeriod: falso.rand(['mo', 'yr', '12m', '/yr' ]),
      }, scored),
      age: {
        min: this.wrapScore(minAge, scored), 
        max: this.wrapScore(falso.randNumber({min: minAge + 1, max: 99}), scored), 
      },
      wholeCategory: this.wrapScore(falso.randBoolean(), scored),
      deductible: this.wrapScore(falso.randNumber({min: 1, max: 20}) * 10, scored),
      lifetimeMaximum: this.wrapScore(falso.randNumber({min: 1, max: 900}) * 10, scored),
      waitingPeriod: this.wrapScore(falso.rand([{
        months: falso.randNumber({min: 1, max: 12}),
        days: 0,
        years: 0 
      }, {
        months: 0,
        days: falso.randNumber({min: 1, max: 31}),
        years: 0
      }, {
        months: 0,
        days: 0,
        years: falso.randNumber({min: 1, max: 360}),
      }]), scored),
      correlatedWith: this.genCorelatedWith(corelated, scored),
      caveat: [falso.randText({length: falso.randNumber({min: 0, max: 3})})].flat(),
    }
  }

  genOverallPlan(scored: boolean): IV2.ISchemaAllPlan {
    return ({
      patient: this.genPatient(),
      plan: this.genPlan(scored),
      ticket: this.genTicket()
    })
  }
}