import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { VerrificButtonProps } from './Button.type';
import { useStyles } from './Button.style';

export const Button = ({
  children,
  type,
  disabled,
  leftIcon,
  rightIcon,
  onClick,
  waitForAnimation,
  borderRadius,
  autoFocus,
  style
}: VerrificButtonProps) => {
  const classes = useStyles();
  let pickedClass = classes[type];
  if (disabled) {
    const newType = `${type}Disabled` as keyof typeof classes;
    pickedClass = classes[newType];
  }

  const handleAnimationEnd = () => {
    if (onClick && waitForAnimation) {
      onClick();
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    if (onClick && !waitForAnimation) {
      onClick(event);
    }
  };

  const buttonStyle = 
    style && borderRadius ?                         // exists style and radius
      {...style, borderRadius: `${borderRadius}`} : // ececution of style and raduis exists
      style && !borderRadius ?                      // exists style but not radius
        style :                                     // ececution of style exists but not radius
        !style && borderRadius ?                    // exists radius but not style
          {borderRadius: `${borderRadius}`} :       // ececution of radius exists but not setle
          {};                                       // style and radius not exists


  return (
    <MuiButton 
      autoFocus={autoFocus} 
      style={buttonStyle} 
      disabled={disabled} 
      onAnimationEnd={handleAnimationEnd} 
      variant="contained" 
      className={pickedClass} 
      startIcon={leftIcon} 
      endIcon={rightIcon} 
      onClick={handleClick} >
      {children}
    </MuiButton>
  );
};
