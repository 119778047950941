import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SessionContextProps, SessionProviderProps } from './SessionContext.type';
import { Auth0Verrific } from 'verrific-plus-schema';
import { useAuth0 } from '@auth0/auth0-react';

import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from '../Components/Button';

const TEST_MULTIPLICATOR = 1;
const TIME_OFFSET = TEST_MULTIPLICATOR * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

export const formatTime = (sec: number) => {
  let minutes = `${Math.floor(sec/60)}`;
  if (minutes.length < 2) {
    minutes = `0${minutes}`;
  }

  let seconds = `${Math.floor(sec%60)}`;
  if (seconds.length < 2) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
};

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children
}: SessionProviderProps) => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [sessionPopupOpen, setSessionPopupOpen] = useState(false);
  const [timeTilSessionEnd, setTimeTilSessionEnd] = useState<number>(0);

  const isInitialized = useRef<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const logoutToastId = React.useRef<any>(null);
  const [userClock, setUserClock] = useState<string>('00:00');
  const sessionBroadcastChannel = React.useRef<BroadcastChannel>(new BroadcastChannel('verrific-session'));
  const refreshRef = useRef<any>();

  const cooldown = 30 * 1000; // 30s;
  const treshhold = 800; // 800ms

  const verbose = localStorage.getItem('SHOW_TOKEN_REFRESH_LOGS') === 'true';

  const log = (...messages: any[]) => {
    if (verbose) {
      console.log(...messages);
    }
  };

  const nextRefreshCanBePerforedAfter = useRef({
    get: () => {
      const nextRefresh = localStorage.getItem('v+nextRefreshCanBePerforedAfter');
      if (!nextRefresh) {
        localStorage.setItem('v+nextRefreshCanBePerforedAfter', +new Date() + '');
        return +new Date();
      } else {
        return +new Date(Number(nextRefresh));
      }
    },
    set: (value: number) => {
      localStorage.setItem('v+nextRefreshCanBePerforedAfter', `${value}`);
    }
  });

  useEffect(() => {
    sessionBroadcastChannel.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'newToken') {
        const token = data.token;
        Auth0Verrific.setToken(token);
        const now = +new Date();
        nextRefreshCanBePerforedAfter.current.set(now + cooldown);
      }
    };
    return () => {
      sessionBroadcastChannel.current.close();
    };
  }, []);

  const refreshToken = async () => {
    try {
      await getAccessTokenSilently({cacheMode: 'off', detailedResponse: true});
      const token = await getIdTokenClaims();
      if (token) {

        Auth0Verrific.setToken(token);
        localStorage.setItem('v+token', token.__raw);
        sessionBroadcastChannel.current.postMessage(JSON.stringify(
          {
            type: 'newToken',
            token
          }
        ));
        log('token refreshed');
      } else {
        alert('Unauthorized - token refresh from other tab');
        location.href = '/logout';
      }
    } catch (e) {
      // visible token error handling
      console.warn(e);
      toast(`Unknown AUTH API error: ${(e as Error).message}`, {
        type: 'error'
      });
      location.href = '/logout';
    }
  };

  const shouldRefreshToken = () => {
    const exp = +Auth0Verrific.getExpirationDate();
    const now = +new Date();
    return exp - now <= ONE_HOUR;
  };

  const forceRefreshToken = refreshToken;

  const deboundeTokenRefresh = () => {
    log('user take action');
    const now = +new Date();
    if (nextRefreshCanBePerforedAfter.current.get() < now) {
      if (refreshRef.current) {
        clearTimeout(refreshRef.current);
        refreshRef.current = undefined;
      }
  
      refreshRef.current = setTimeout(() => {
        if (shouldRefreshToken()) {
          refreshToken();
          nextRefreshCanBePerforedAfter.current.set(now + cooldown);
          refreshRef.current = undefined;
        }
      }, treshhold);
    }

  };

  const checkSession = useCallback(() => {
    if (
      (+Auth0Verrific.getExpirationDate() - (TIME_OFFSET) <= +new Date()) && 
        (+Auth0Verrific.getExpirationDate() >= +new Date())  
    ) {
      if (timeTilSessionEnd >= 0) {
        setTimeTilSessionEnd(Math.floor((+Auth0Verrific.getExpirationDate() - +new Date()) / 1000));
      } else {
        setTimeTilSessionEnd(0);
      }
      setSessionPopupOpen(true);
    } else if (+Auth0Verrific.getExpirationDate() < +new Date()) {
      let whereReturn = '';
      if (location.href !== location.origin) {
        whereReturn = `${location.href}`.replace(location.origin, '').replaceAll('/','%2F');
      }
      location.href = `/logout${whereReturn ? `?sessionEnd=true&return=${whereReturn}` : ''}`;
    } else {
      setTimeTilSessionEnd(0);
      setSessionPopupOpen(false);
    }
    setUserClock(formatTime(Math.floor((+Auth0Verrific.getExpirationDate() - +new Date()) / 1000)));
  }, []);

  useEffect(() => {
    if (sessionPopupOpen) {
      // removed til Viveks decision to make sound
      // beep();
      logoutToastId.current = toast(message, {
        autoClose: false,
        onClick: () => {
          refreshToken();
        },
        type: 'warning',
        
      });
    } else if (!sessionPopupOpen && logoutToastId.current) {
      toast.dismiss(logoutToastId.current);
    }
  }, [sessionPopupOpen]);

  const message = useMemo<JSX.Element>(
    () => <>
      Session is almost ending.<br/>
      <span style={{textDecoration: 'underline'}}><b>Time left: <span style={{color: 'rgba(0, 0, 0, 0.87)'}}>{formatTime(timeTilSessionEnd)}</span> min</b></span><br/>
      Click {'"Extend session"'} or take any page action to extend it.<br/>
      After the countdown finishes, you will be logged out!
      <br/>
      <br/>
      <Button style={{
        width: '100%'
      }} type='primary'>Extend session</Button>
    </>, 
    [timeTilSessionEnd]
  );

  useEffect(() => {
    if (sessionPopupOpen) {
      toast.update(logoutToastId.current, {
        render: message
      });
    }
  }, [timeTilSessionEnd]);

  const initWatcher = () => {
    if (!isInitialized.current) {
      document.body.addEventListener('touchstart', deboundeTokenRefresh);
      document.body.addEventListener('click', deboundeTokenRefresh);
      document.body.addEventListener('scroll', deboundeTokenRefresh);
      // makes too many requests
      // document.body.addEventListener('mousemove', deboundeTokenRefresh);
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState == 'visible') {
          deboundeTokenRefresh();
        }
      });
      document.body.addEventListener('keydown', deboundeTokenRefresh);

      intervalRef.current = setInterval(checkSession, 1000);
    }
  };

  const init = () => {
    if (!isInitialized.current) {
      initWatcher();
      isInitialized.current = true;
    }
  };

  useEffect(() => {
    Auth0Verrific.setPostLoginAction(init);
    if (isInitialized.current) {
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        isInitialized.current = false;
      };
    }
  }, [isAuthenticated]);

  return (
    <SessionContext.Provider 
      value={{
        init,
        forceRefreshToken,
        userClock
      }}
    >
      <ToastContainer style={{
        top: '68px'
      }}/>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = (): SessionContextProps => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};