import React, { useEffect, useState } from 'react';
import { IDynamicLogoProps } from './DynamicLogo.type';
import Logo from '../../Assets/logo.svg';
import { envLabel, envLabelLoading, mainBlock, miniImg, scheduleLogoFix, splashImg, topBarImg } from './DynamicLogo.style';
import { getEnvValue } from '../../Utils/FrontEnvs';
import { Interface } from 'verrific-plus-schema';

const DynamicLogo = ({type, className}: IDynamicLogoProps) => {
  const [env, setEnv] = useState('prod');
  
  const init = async () => {
    const domainsRaw = await getEnvValue(Interface.FlagName.DOMAIN_ENV_MAP);
    if (domainsRaw) {
      const domains = Object.entries(domainsRaw);
      const [foundDom] = (domains.find(([_key, doms]) => (doms as string[]).includes(location.host)) || []);
      setEnv(foundDom || 'prod');
    } else {
      setEnv('prod');
    }
  };

  useEffect(() => {
    init();
  }, []);
  
  if (type === 'TOPBAR') {
    return (
      <div style={{...mainBlock, ...scheduleLogoFix}}>
        <img 
          style={topBarImg}
          src={Logo} 
          alt='VeriSmart' 
        />
        { env && env !== 'prod' && (
          <span style={envLabel}>
            {env.toUpperCase()}
          </span>
        )}
      </div>
    );
  } else if (type === 'LOADING') {
    return (
      <div style={mainBlock}>
        <img className={className} src={Logo}/>
        { env && env !== 'prod' && (
          <span style={envLabelLoading}>
            {env.toUpperCase()}
          </span>
        )}
      </div>
    );
  } else if (type === 'MINI') {
    <div style={mainBlock}>
      <img 
        style={miniImg}
        src={Logo} 
        alt='VeriSmart' 
      />
    </div>;  
  }
  return <></>;
};

export default DynamicLogo;