import CommunicationService from '../../../Utils/CommunicationService';
// import './OrganizationPage.api.mock';

export const getOrganization = async (domain?: string, name?: string) => {
  const data: {[key: string]:  string} = {};

  if (domain) {
    data.organizationDomain = domain;
  }
  if (name) {
    data.organizationName = name;
  }

  const response = await CommunicationService.getInstance().publicCall<any>({
    type: 'POST',
    path: '/api/auth/organization',
    forceHttp: true,
    body: data
  });

  return response.body;
};