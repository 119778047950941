type TSeverity = 'log' | 'info'| 'error' | 'warn';

export class BugReportOverride{
  private constructor (){ 
    this.initConsoleOverride();
  }
    private static _INSTANCE: BugReportOverride;
    public static getInstance(): BugReportOverride{
      if(!BugReportOverride._INSTANCE){
        BugReportOverride._INSTANCE = new BugReportOverride;
      }
      return BugReportOverride._INSTANCE;
    }

    private _logs: {type: TSeverity, messages: any[]}[] = [];

    private _consoleProto = (type: TSeverity, logger: (...args: any[]) => void) => (...args: any[]) => {
      this._logs.push({
        type,
        messages: args
      });
      logger(...args);
    };

    private initConsoleOverride() {
      // const { 
      //   log: logImpl, 
      //   info: infoImpl, 
      //   error: errorImpl, 
      //   warn: warnImpl 
      // } = console;
      
      // console.log = this._consoleProto('log', logImpl);
      // console.info = this._consoleProto('info', infoImpl);
      // console.error = this._consoleProto('error', errorImpl);
      // console.warn = this._consoleProto('warn', warnImpl);
    }

    public getLogs() {
      return this._logs;
    }
}