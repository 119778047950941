export type sourcesOrigins =
  | 'onderful'
  | 'changeHealthcare'
  | 'verrific'
  | 'onederfulStandardized';
export enum ClearinghouseName {
  ONEDERFUL = 'onederful',
  ONEDERFUL_STANDARDIZED = 'onederfulStandardized',
  CHANGE_HEALTHCARE = 'changeHealthcare',
}
export type rawDataOrigins = 
  ClearinghouseName
  | 'onderful'
  | 'onederfulMerged'
  | 'adapters'
  | 'verrific'
  | 'verrificPlus';
