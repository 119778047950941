import CommunicationService from '../Utils/CommunicationService';

export const isDOStripeIntegrated = async () => {
  const result = await CommunicationService.getInstance().secureCall<any>({
    type: 'GET',
    path: '/api/stripe'
  });
  if (result.body) {
    return result.body.valid;
  }
  return false;
};

export const isDOStripePaid = async () => {
  const result = await CommunicationService.getInstance().secureCall<any>({
    type: 'GET',
    path: '/api/stripe/subscription/valid'
  });
  if (result.body) {
    return result.body.valid;
  }
  return false;
};