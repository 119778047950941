export {};

declare global {
  interface Array<T> {
    isEmpty(): boolean;
  }
  interface String {
    capitalizeFirstLetter(): string;
  }
}

Array.prototype.isEmpty = function () {
  return this.length === 0;
};

String.prototype.capitalizeFirstLetter = function () {
  const result = this.charAt(0).toUpperCase() + this.slice(1);
  return result;
};
