export enum MigrationEnum {
  V1_V2 = 1,
  V2_V3
}

export enum VersionEnum {
  V1 = 1,
  V2 = 2,
  V3 = 3
}

export const versionArray = Object.keys(VersionEnum).filter((v) => !isNaN(Number(v)));

export type TMigration = `${MigrationEnum}`;

export type TVersion = 1 | 2 | 3;

export type TMigrationFunction = (schema: any) => any;

export type TMigrator = {
  up: TMigrationFunction,
  down: TMigrationFunction,
  upTicket: TMigrationFunction,
  downTicket: TMigrationFunction
};