 enum NameOfChange
    {
      VALUE_CREATED = 'add',
      VALUE_UPDATED = 'change',
      VALUE_DELETED = 'remove',
      VALUE_UNCHANGED = '---'
    }
export class deepDiffMapper {
      private static _instance: deepDiffMapper;
      public static getInstance() {
        deepDiffMapper._instance = new deepDiffMapper();
        return deepDiffMapper._instance;
      }


      public changeArray: { [x: string]: {typeOfChange: string, oldVal: any, newVal: any} }[] = [];

      public map = (oldValue: any, newValue: any, path: string) => {
        if (this.isFunction(oldValue) || this.isFunction(newValue)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(oldValue) || this.isValue(newValue)) {
          const returnObj = {
            type: this.compareValues(oldValue, newValue),
            original: oldValue,
            updated: newValue,
          };

          const changeOnly = {
            [path]: {
              typeOfChange: this.compareValues(oldValue, newValue), 
              oldVal: oldValue, 
              newVal: newValue
            }
          };
          if (returnObj.type != NameOfChange.VALUE_UNCHANGED) {
            this.changeArray.push(changeOnly);
            if(!oldValue){
              return this.changeArray;
            } else {
              return returnObj;
            }
          }
          return undefined;
        }

        let diff = {};
        let foundKeys: {[key: string]: any} = {};
        for (const key in oldValue) {
          const propKey = `${path}.${key}`;

          if (this.isFunction(oldValue[key])) {
            continue;
          }

          let value2 = undefined;
          if (newValue[key] !== undefined) {
            value2 = newValue[key];
          }

          const mapValue = this.map(oldValue[key], value2, propKey);
          foundKeys = { ...foundKeys, [key]: true };
          if (mapValue) {
            diff = {...diff, [propKey]: mapValue};
          }
        }
        for (const key in newValue) {
          const propKey = `${path}.${key}`;

          if (this.isFunction(newValue[key]) || foundKeys[key] !== undefined) {
            continue;
          }

          const mapValue = this.map(undefined, newValue[key], propKey);
          if (mapValue) {
            diff = {...diff, [propKey]: mapValue};
          }
        }

        if (Object.keys(diff).length > 0) {
          return this.changeArray;
        }
        return undefined;
      }
      
      compareValues(oldValue: any, newValue: any) {
        if (oldValue === newValue) {
          return NameOfChange.VALUE_UNCHANGED;
        }
        if (this.isDate(oldValue) && this.isDate(newValue) && oldValue.getTime() === newValue.getTime()) {
          return NameOfChange.VALUE_UNCHANGED;
        }
        if (oldValue === undefined) {
          return NameOfChange.VALUE_CREATED;
        }
        if (newValue === undefined) {
          return NameOfChange.VALUE_DELETED;
        }
        return NameOfChange.VALUE_UPDATED;
      }
      isFunction(x: any) {
        return Object.prototype.toString.call(x) === '[object Function]';
      }
      isArray (x: any) {
        return Object.prototype.toString.call(x) === '[object Array]';
      }
      isDate (x: any) {
        return Object.prototype.toString.call(x) === '[object Date]';
      }
      isObject (x: any) {
        return Object.prototype.toString.call(x) === '[object Object]';
      }
      isValue (x: any) {
        return !this.isObject(x) && !this.isArray(x);
      }
}