import React from 'react';

export const splashImg: React.CSSProperties = {
  marginTop: 'calc(50vh - 141px)',
  width: '283px',
  height: '283px',
  transition: 'all 0.5s',
  animation: '$heartbeat 1000ms',
  animationIterationCount: 'infinite'
};

export const miniImg: React.CSSProperties = {
  height: '56px',
  marginTop: '0px'
};

export const topBarImg: React.CSSProperties = {
  height: '28px',
  marginLeft: '5px',
  marginTop: '10px'
};

export const mainBlock: React.CSSProperties = {
  position: 'relative'
};

export const envLabel: React.CSSProperties = {
  position: 'absolute',
  right: 0,
  bottom: '-5px',
  padding: '0px 2px',
  color: 'white',
  fontSize: '11px',
  background: 'rgb(255, 89, 107)'
};

export const envLabelLoading: React.CSSProperties = {
  position: 'absolute',
  right: 0,
  bottom: 'calc(50vh - 40px)',
  padding: '0px 2px',
  color: 'white',
  fontSize: '11px',
  background: 'rgb(255, 89, 107)'
};

export const scheduleLogoFix: React.CSSProperties = {
  maxWidth: '115px',
  top: '-10px'
};

