import { mapValues } from 'lodash';

export const ModifySchemaObjectsRecursively = (
  obj: any,
  callback: (value: any) => any,
  condition: (value: any) => any
): any => {
  return mapValues(obj, (value) => {
    if (!isObject(value)) {
      if (Array.isArray(value)) {
        return value.map((element) => {
          if (isObject(element)) {
            if (condition(element)) {
              return callback(element);
            } else {
              return ModifySchemaObjectsRecursively(element, callback, condition);
            }
          }
          return element;
        });
      }

      return value;
    }
    if (condition(value)) {
      return callback(value);
    } else {
      return ModifySchemaObjectsRecursively(value, callback, condition);
    }
  });
};

export function isObject(field: any) {
  return !!field && typeof field === 'object' && !Array.isArray(field);
}