import * as V1_V2 from './v1-v2.migration';
import * as V2_V3 from './v2-v3.migration';
// import * as V2_V3 from './v2-v3.migration';
import { TMigrator, TVersion } from './migrators.types';

const Migrations: { [key in TVersion]?: TMigrator } = {
  1: V1_V2,
  2: V2_V3
}

export * as MigratorTypes from './migrators.types';
export { Migrations };