import React, {
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import { InfoBar } from './InfoBar';
import { InfoBarContextProps, InfoBarProviderProps, InfoBarType } from './InfoBarContext.type';

const InfoBarContext = createContext<InfoBarContextProps | undefined>(
  undefined
);

export const InfoBarProvider: React.FC<InfoBarProviderProps> = ({
  showingTime,
  children
}: InfoBarProviderProps) => {
  const [isShowing, setShowing] = useState(false);
  const [type, setType] = useState<InfoBarType>(null);
  const [error, setError] = useState<string | undefined>();
  
  const showInfoBar = (type: InfoBarType, error?: string) => {
    setType(type);
    setShowing(true);
    setError(error);
    setTimeout(() => {
      hideInfoBar();
    }, showingTime);
  };

  const hideInfoBar = () => {
    setType(null);
    setShowing(false);
  };

  const getInfoBar = useCallback(() => {
    if (isShowing) {
      return <InfoBar type={type} onClick={hideInfoBar} error={error} />;
    }
    return <></>;
  }, [type, isShowing, error]);
  

  return (
    <InfoBarContext.Provider
      value={{
        showInfoBar,
        getInfoBar,
        isShowing,
        type
      }}
    >
      {children}
    </InfoBarContext.Provider>
  );
};

export const useInfoBarContext = (): InfoBarContextProps => {
  const context = useContext(InfoBarContext);
  if (context === undefined) {
    throw new Error('useInfoBarContext must be used within a InfoBarProvider');
  }
  return context;
};
