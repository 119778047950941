import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

let rawEnv = location.host.split('.')[0];
if (rawEnv.includes(location.port) && location.port) {
  rawEnv = rawEnv.replace(location.port, '');
}

const mappings: {[key: string]: string} = {
  localhost: 'LOCAL',
  sdbx: 'SANDBOX',
  qa: 'QA',
  alpha: 'ALPHA',
  beta: 'BETA',
  plus: 'PROD'
};

if (mappings[rawEnv] !== mappings.localhost) {
  // Sentry.init({
  //   dsn: 'https://b0c44cc3c398491287124d659a42e778@o1273553.ingest.sentry.io/6468300',
  //   integrations: [new BrowserTracing()],
  //   tracesSampleRate: 1.0,
  //   environment: mappings[rawEnv] || rawEnv,
  //   maxValueLength: 2048,
  //   maxBreadcrumbs: 200
  // });
}