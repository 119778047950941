import React, { ChangeEvent, useEffect, useState } from 'react';
import { FrameLoading } from '../../../Components/FrameLoading';
import { Theme } from '../../../Theme/ThemeProvider';
import { getOrganization } from './OrganizationPage.api';
import { Label, PaperContainer, OrganizationPaper, HeaderBox, HeaderTitle, FormContainer, FromGroup, StandardInput, ErrorMessage, SubmitBlock, SubmitBtn } from './OrganizationPage.style';
import Logo from '../../../Assets/logo.svg';
interface OrganizationPageProps {
  setOrganizationId: React.Dispatch<React.SetStateAction<string | null>>
}

type TFlowState = 'checking_domain' | 'provide_name' | 'have_org_id' | 'loading';

export const ORG_ID_KEY = 'organization_id';

const OrganizationPage = ({setOrganizationId}: OrganizationPageProps) => {
  const [flowState, setFlowState] = useState<TFlowState>('checking_domain');
  const [orgName, setOrgName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const init = async () => {
    let domain = location.hostname;
    if (location.port && location.port !== '80') {
      domain = `${domain}:${location.port}`;
    }
    const organizationRes = await getOrganization(domain);
    if (organizationRes.id) {
      setFlowState('have_org_id');
      localStorage.setItem(ORG_ID_KEY, organizationRes.id);
      setOrganizationId(organizationRes.id);
      return;
    }
    setFlowState('provide_name');
  };

  useEffect(() => {
    init();
  }, []);

  const handleOrgNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setOrgName(e.target.value);
  };

  const handleSubmit = async () => {
    if (!orgName) {
      setErrorMessage('Fill organization name first!');
      return;
    }
    if (orgName.length < 3) {
      setErrorMessage('Organization name is too short!');
      return;
    } 
    setFlowState('loading');
    const organizationRes = await getOrganization(undefined, orgName);
    if (organizationRes.id) {
      setFlowState('have_org_id');
      localStorage.setItem(ORG_ID_KEY, organizationRes.id);
      setOrganizationId(organizationRes.id);
      return;
    } else if (organizationRes.error) {
      setErrorMessage(organizationRes.error);
    }
    setFlowState('provide_name');
  };

  return (
    <Theme>
      <FrameLoading isLoading={['checking_domain', 'loading'].includes(flowState)}>
        <PaperContainer>
          <OrganizationPaper elevation={3}>
            <HeaderBox>
              <img style={{
                height: '56px',
                marginTop: '0px'
              }}
              src={Logo} alt='VeriSmart' />
              <HeaderTitle>Enter organization name</HeaderTitle>
            </HeaderBox>
            <FormContainer>
              <FromGroup>
                <Label>Organization name</Label>
                <StandardInput type="text" placeholder="Organization name" value={orgName} onChange={handleOrgNameChange}/>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </FromGroup>
              <SubmitBlock>
                <SubmitBtn onClick={handleSubmit}>CONTINUE</SubmitBtn>
              </SubmitBlock>
            </FormContainer>
          </OrganizationPaper>
        </PaperContainer>
      </FrameLoading>
    </Theme>
  );
};

export default OrganizationPage;