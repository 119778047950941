import { isArray, isObject } from 'lodash';
import React, { createContext, useContext, useRef } from 'react';
import { BizonStore } from './BizonStore';
import { deepDiffMapper } from '../Helpers/deepCompareChanges';
import { IBizonStoreContextProps, IBizonStoreProviderProps, IListenerCallbackValue, IListersStore } from './BizonStoreContext.type';


/* Creating a context that is used to pass the data to the children. */
const BizonStoreContext = createContext<IBizonStoreContextProps | undefined>(
  undefined
);


/* A way to create a custom event listener. */
const BizonEvents = {
  listeners: {} as IListersStore,
  on: (key: string, listener:(value: IListenerCallbackValue) => void) => {
    const eventCallback = (ev: any) => listener(ev.detail);
    if (!BizonEvents.listeners[key]){
      BizonEvents.listeners[key] = [];
    }
    BizonEvents.listeners[key].push({listener, eventCallback});
    document.addEventListener(key, eventCallback);
  },
  unsubscribe: (key: string, listener:(value: IListenerCallbackValue) => void) => {
    const eventCallback = BizonEvents.listeners[key].find(obj => obj.listener.toString() === listener.toString())?.eventCallback;
    if(eventCallback){
      document.removeEventListener(key, eventCallback);
    }
  },
  once: (key: string, listener:(value: IListenerCallbackValue) => void) => {
    const onceCallback = (value: IListenerCallbackValue) => {
      BizonEvents.unsubscribe(key, onceCallback);
      listener(value);
    };
    BizonEvents.on(key, onceCallback);
  },
  emit: (key: string, data: IListenerCallbackValue) => {
    const evt = new CustomEvent(key, { detail: data });
    document.dispatchEvent(evt);
  },
};

export const BizonStoreProvider = ({children}: IBizonStoreProviderProps) => {
  const bizonStore = BizonStore.getInstance();
  const getBizonData = (path: string) => bizonStore.readData(path);

  /* Creating a ref object that is used to store the listeners and the signals. */
  const SignalStore = useRef<{[path: string]: { 
    listener: (value: IListenerCallbackValue) => void, 
    signal: (value: any) => void 
      }[]}>({});

  /**
   * It subscribes to a path and listens for changes.
   * @param {string} path - The path to the signal.
   * @param listener - (value: IListenerCallbackValue) => void
   */
  const subscribe = (path: string, listener: (value: IListenerCallbackValue) => void) => {
    BizonEvents.on(path, listener);
    if(path){
      if(!SignalStore.current[path]){
        SignalStore.current[path] = [];
      }
      SignalStore.current[path].push({listener, signal: (value: any) => {BizonEvents.emit(path, value);} });
    }
  };

  /**
 * It removes a listener from the SignalStore.
 * @param {string} path - The path to the data you want to listen to.
 * @param listener - (value: IListenerCallbackValue) => void
 */
  const unsubscribe = (path: string, listener: (value: IListenerCallbackValue) => void) => {
    if(SignalStore.current[path].find(signal => signal.listener.toString() === listener.toString())){
      BizonEvents.unsubscribe(path, listener);
      SignalStore.current[path] = SignalStore.current[path].filter(signal => signal.listener.toString() !== listener.toString());
    }
  };

  /**
   * It takes a string, splits it into an array, then iterates over the array and creates a new object
   * with the array elements as keys and the value 'change'
   * @param {string} path - The path of the element that has changed.
   * @returns An object with the path of the changed element and its parents.
   */
  const parentChange = (path: string) => {
    const splitedPath = path.split('.');
    let changedElementsArray = {};
    for (let index = 0; index < splitedPath.length; index++) {
      const newPath = splitedPath.slice(0, index+1);
      changedElementsArray = {...changedElementsArray, [newPath.toString().replaceAll(',', '.')]: 'change'};
    }
    return changedElementsArray;
  };

  const setBizonData = (path: string, value: any, cache?: boolean, withoutHook?: boolean) => {
    /* Getting the old value of the path. */
    const oldValue = bizonStore.readData(path);

    /* Getting the changes that are made to the data. */
    const changes = {
      parentChanges: parentChange(path),
      childrenChanges: deepDiffMapper.getInstance().map(oldValue, value, path)
    };

    /* Checking if the changes.childrenChanges is an object. If it is, then it is assigning it to the
    childrenChanges variable. */
    let childrenChanges: undefined | { [key: string]: any }[];
    if(changes.childrenChanges && isObject(changes.childrenChanges)){
      childrenChanges = (changes.childrenChanges as any); 
    }

    /* Creating an array of objects that contains the key, listener and signal. */
    const signals: { key: string, listener: (value: IListenerCallbackValue) => void, signal: (value: any) => void }[] = [];
    if(childrenChanges && isArray(childrenChanges)){
      Object.values(childrenChanges).map(value => {
        Object.keys(value).map(key => {
          const signal = SignalStore.current?.[key];
          if(signal){
            signal.forEach(listener => {
              signals.push({...listener, key});
            });
          }
        });
      });
    }

    /* Checking if the current path is in the parentChanges. If it is, then it is pushing the signal to
    the signals array. */
    const currentPath = Object.keys(changes.parentChanges).find(key => key === path);
    if(currentPath){
      const signal = SignalStore.current?.[path];
      if(signal){
        signal.forEach(listener => {
          signals.push({...listener, key: path});
        });
      }
    }

    /* Storing the data in the BizonStore. */
    bizonStore.storeData(path, value, cache, withoutHook);

    /* Sending the changes to the listeners. */
    signals.forEach(signalObject => {
      if(path === signalObject.key){
        signalObject.signal({typeOfChange: 'change', newVal: value, oldVal: oldValue, key: path});
      }
      if(childrenChanges && isArray(childrenChanges)){
        childrenChanges.map(child => {
          Object.keys(child).map(childKey => {
            if(childKey === signalObject.key){
              const {typeOfChange, oldVal, newVal} = child[childKey];
              const { key, signal } = signalObject;
              signal({typeOfChange, newVal, oldVal, key});
            }
          });
        });
      }
    });  
  };

  const removeBizonData = (path: string) => {
    bizonStore.removeData(path);
  };


  const getBizonStore = () => {
    return bizonStore.getStore();
  };

  /**
   * It returns the current value of the SignalStore.current property
   * @returns The current value of the SignalStore.current property.
   */
  // const showSignalList = () => {
  //   return SignalStore.current;
  // };

  // /* Just a way to show the SignalStore in the console. */
  // (window as any).showSignalList = showSignalList();

  return(
    <BizonStoreContext.Provider
      value={{
        getBizonData,
        setBizonData,
        subscribe,
        SignalStore,
        unsubscribe,
        removeBizonData,
        getBizonStore
      }}>
      {children}
    </BizonStoreContext.Provider>
  );
};


/**
 * It returns the context object that was created by the BizonStoreProvider
 * @returns The context object is being returned.
 */
export const useBizonStoreContext = (): IBizonStoreContextProps => {
  const context = useContext(BizonStoreContext);
  if (context === undefined) {
    throw new Error('useInfoBarContext must be used within a InfoBarProvider');
  }
  return context;
};
