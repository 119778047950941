import { forIn, cloneDeep } from 'lodash';
import * as V2 from '../interfaces/interfaces.v2';
import * as V3 from '../interfaces/interfaces.v3';
import {
  isObject,
  ModifySchemaObjectsRecursively,
} from '../utils/schema-object-modifier';

const NEW_CODE_FORMAT = /^ADA:D[0-9]{4}$/;
const OLD_CODE_FORMAT = /^D[0-9]{4}$/;

const codeToNewFormat = (code: string) => {
  if (code.match(NEW_CODE_FORMAT)) {
    return code;
  }
  if (code.match(OLD_CODE_FORMAT)) {
    return `ADA:${code}`;
  }
  console.log(code);
  throw new Error(`Unrecognized code format: ${code}`);
};

// V3.ISchemaPlan should be added as the return type when reenabling migrators
export const up = (schema: V2.ISchemaPlan) => {
  // Convert all caveats from string to array of strings
  let newSchema: V3.ISchemaPlan = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.caveat) {
        // caveat to string in case it is not string
        field.caveat = [field.caveat + ''];
      }

      return field;
    },
    (item: any) => {
      return (
        isObject(item) &&
        item.caveat !== undefined &&
        !Array.isArray(item.caveat)
      );
    },
  );

  // Convert all caveats from string to array of strings
  newSchema = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.caveat) {
        // caveat to string in case it is not string
        field.caveat = field.caveat.map((caveat: string) => {
          return {
            value: caveat,
            score: 1,
          };
        });
      }

      return field;
    },
    (item: any) => {
      return (
        isObject(item) &&
        item.caveat !== undefined &&
        !!Array.isArray(item.caveat)
      );
    },
  );

  // Add plan type
  (newSchema.details as V3.IDetailsScored | V3.IDetails).planType = 'unknown';

  // Delete all wholeCategory fields
  newSchema = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.wholeCategory) {
        // delete object
        delete field.wholeCategory;
      }

      return field;
    },
    (item: any) => {
      return isObject(item) && !!item.wholeCategory;
    },
  );

  // Converting codes from top level IAdaObject to new format
  newSchema = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToNewFormat(field.code);
      }
      if (!!field.waitingPeriod?.value?.codes) {
        field.waitingPeriod.value.codes = field.waitingPeriod.value.codes.map(
          (code: string) => codeToNewFormat(code),
        );
      }
      if (!!field.exceptions) {
        for (const exception in field.exceptions) {
          field.exceptions[exception].code = codeToNewFormat(
            field.exceptions[exception].code,
          );
        }
      }

      return field;
    },
    (item: any) => {
      return !!item.code?.match(OLD_CODE_FORMAT);
    },
  );

  // Converting nested codes in IAdaObject to new format
  newSchema = ModifySchemaObjectsRecursively(
    schema,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToNewFormat(field.code);
      }

      return field;
    },
    // TODO: check if path of checked item is in nested code or not and replace this
    // condition instead item.appliesToAnnualMax
    (item: any) => {
      return (
        // checking if it isn't top level IAdaObject
        item.appliesToAnnualMax === undefined &&
        typeof item.code == 'string' &&
        !!item.code?.match(OLD_CODE_FORMAT)
      );
    },
  );

  // ADA codes section
  let adaCodes = schema.benefits?.adaCodes;
  forIn(adaCodes, (adaObject, code) => {
    if (!adaObject) {
      return;
    }

    // Converting categories to new format
    adaObject.category = getCategoryForCode(code as V2.TAdaCode);

    // Converting subcategories to new format
    if (getSubcategoryForCode(code as V2.TAdaCode)) {
      (adaObject as any).subcategory = getSubcategoryForCode(
        code as V2.TAdaCode,
      );
    }

    // Converting codes in object keys to new format
    if (code.match(OLD_CODE_FORMAT)) {
      const copiedAdaObject = cloneDeep(adaObject);
      delete adaCodes[code as V2.TAdaCode];
      // adaCodes = { ...adaCodes, [codeToNewFormat(code)]: copiedAdaObject };

      adaCodes[codeToNewFormat(code) as V2.TAdaCode] = copiedAdaObject;
    }

    delete adaObject.wholeCategory;
    delete adaObject.conflicts;
  });

  if (!!schema.caveat && !!Array.isArray(schema.caveat)) {
    newSchema.caveat = schema.caveat.map((caveat: string) => {
      return {
        value: caveat,
        score: 1,
      } as V3.IScoredField<string>;
    });
  }

  if (newSchema.benefits?.adaCodes) {
    newSchema.benefits.adaCodes = adaCodes as V3.TAdaCodes;
  }

  newSchema.schemaVersion = '3';

  return newSchema;
};

// V3.ISchemaPlan should be added as the return type when reenabling migrators
export const upTicket = (verification: V2.ISchemaAllPlan) => {
  const newVerification: V3.ISchemaAllPlan = verification as any;

  const draft = verification.ticket?.draft;
  const plan = verification.plan;

  // TODO: Handle case if old draft is array and return always array
  if (!!draft) {
    if (Array.isArray(draft)) {
      newVerification.ticket.draft = draft.map((draft) => up(draft));
    }
  }

  // Converting codes in patient.history to new format
  // TODO: Check if code in history has always key 'code'
  newVerification.patient = ModifySchemaObjectsRecursively(
    newVerification.patient,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToNewFormat(field.code);
      }

      return field;
    },
    (item: any) => {
      return !!item.code?.match(OLD_CODE_FORMAT);
    },
  );

  // Add coverage status
  newVerification.patient.coverageStatus = 'unknown';

  newVerification.schemaVersion = '3';

  return newVerification;
};

const codeToOldFormat = (code: string) => {
  if (code.match(OLD_CODE_FORMAT)) {
    return code;
  }
  if (code.match(NEW_CODE_FORMAT)) {
    return code.split(':')[1];
  }
  throw new Error(`Unrecognized code format: ${code}`);
};

// V2.ISchemaPlan should be added as the return type when reenabling migrators
// ad wholeCateogry?
export const down = (schema: V3.ISchemaPlan) => {
  delete schema.planNotes;
  let newAdaCodes = schema.benefits.adaCodes;
  forIn(newAdaCodes, (adaObject) => {
    if (!adaObject) {
      return;
    }
    delete adaObject.subcategory;
  });

  let oldSchema: V2.ISchemaPlan = schema as any;

  // removing planType
  delete (schema.details as any).planType;

  // Converting codes on top level IAdaObject to old format
  oldSchema = ModifySchemaObjectsRecursively(
    oldSchema,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToOldFormat(field.code);
      }
      if (!!field.waitingPeriod?.value?.codes) {
        const trasformedCodes = field.waitingPeriod.value.codes.map(
          (code: string) => codeToOldFormat(code),
        );
        field.waitingPeriod.value.codes = trasformedCodes;
      }
      if (!!field.exceptions) {
        for (const code in field.exceptions) {
          const copiedExceptionObject = JSON.parse(
            JSON.stringify(field.exceptions[code]),
          );
          delete field.exceptions[code];
          field.exceptions = {
            ...field.exceptions,
            [codeToOldFormat(code)]: copiedExceptionObject,
          };
        }
      }

      return field;
    },
    (item: any) => {
      return !!item.code?.match(NEW_CODE_FORMAT);
    },
  );

  // Converting nested codes in IAdaObject to old format
  oldSchema = ModifySchemaObjectsRecursively(
    oldSchema,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToOldFormat(field.code);
      }

      return field;
    },
    (item: any) => {
      // TODO: check if path of checked item is in nested code or not and replace this
      // condition instead item.appliesToAnnualMax
      return (
        // checking if it isn't top level IAdaObject
        item.appliesToAnnualMax === undefined &&
        !!item.code?.match(NEW_CODE_FORMAT)
      );
    },
  );

  let adaCodes = oldSchema.benefits.adaCodes;
  forIn(adaCodes, (adaObject, key) => {
    if (!adaObject) {
      return;
    }

    if (key.includes('SUB:') || key.includes('CAT:')) {
      delete adaCodes[key as V2.TAdaCode];
    }

    if (key.match(NEW_CODE_FORMAT)) {
      const copiedAdaObject = JSON.parse(JSON.stringify(adaObject));
      delete adaCodes[key as V2.TAdaCode];
      const codeOldFormat = codeToOldFormat(key);

      // adaCodes = { ...adaCodes, [codeOldFormat]: copiedAdaObject };
      adaCodes[codeOldFormat as V2.TAdaCode] = copiedAdaObject;
    }

    if (!(key.includes('SUB:') || key.includes('CAT:'))) {
      adaObject.category = getOldCategoryByCode(
        codeToOldFormat(key) as V2.TAdaCode,
      );
      adaCodes[codeToOldFormat(key) as V2.TAdaCode] = adaObject;
    }
  });

  oldSchema.benefits.adaCodes = adaCodes;

  oldSchema.schemaVersion = '2';

  return oldSchema;
};

// V2.ISchemaPlan should be added as the return type when reenabling migrators
// what about draft? Old format was array, new is single value
export const downTicket = (ticket: V3.ISchemaAllPlan) => {
  // Viveks wish from 11/14/2022 to remove migrators

  // Removing patient.unmapped history node
  delete ticket.patient.unmappedHistory;

  const copiedTicket = cloneDeep(ticket);
  // const copiedTicket = JSON.parse(JSON.stringify(ticket));
  const oldTicket: V2.ISchemaAllPlan = copiedTicket as any;

  if (copiedTicket.plan) {
    if (Array.isArray(copiedTicket.plan)) {
      oldTicket.plan = copiedTicket.plan.map((plan: V3.ISchemaPlan) =>
        down(plan),
      );
    } else {
      oldTicket.plan = down(copiedTicket.plan);
    }
  }

  // Support only multiple plans in draft

  if (!!copiedTicket.ticket.draft) {
    if (copiedTicket.ticket.draft.length > 0 && oldTicket.ticket) {
      if (copiedTicket.ticket.draft.length > 1) {
        console.log(
          'The data compatible with Schema V3 had multiple plans in the draft array, so the migrator migrated only the first plan from the draft array.',
        );
      }
      oldTicket.ticket.draft = down(copiedTicket.ticket.draft[0]);
    }
  }

  // Converting codes in patient.history to old format
  oldTicket.patient = ModifySchemaObjectsRecursively(
    oldTicket.patient,
    (field: any) => {
      if (!!field.code) {
        field.code = codeToOldFormat(field.code);
      }

      return field;
    },
    (item: any) => {
      return !!item.code?.match(NEW_CODE_FORMAT);
    },
  );

  delete (oldTicket.patient as any).coverageStatus;
  delete (oldTicket.patient as any).planEndDate;

  oldTicket.schemaVersion = '2';

  return oldTicket;
};

// Move fallback tree to a separate file in schema v3

const fallbackTree = {
  'CAT:Preventative': {
    min: 0,
    max: 1999,
    subcategories: {
      'SUB:Diagnostic': {
        min: 0,
        max: 999,
        'SUB:Exams': {
          min: 0,
          max: 199,
        },
        'SUB:X-Ray': {
          min: 200,
          max: 399,
          equal: [274, 270, 272, 271, 277],
        },
      },
      'SUB:Prophylaxis': {
        min: 1100,
        max: 1199,
      },
    },
  },
  'CAT:Basic': {
    // Verify max and min
    min: 2000,
    max: 4999,
    subcategories: {
      'SUB:Restorative': {
        min: 2000,
        max: 2599,
        excluded: [2950],
      },
      'SUB:Oral & Maxillofacial Surgery': {
        min: 7000,
        max: 7899,
        excluded: [7210, 7220, 7230, 7240],
      },
      'SUB:Endodontics': {
        min: 3000,
        max: 3999,
      },
      'SUB:Periodontics': {
        min: 4000,
        max: 4999,
        excluded: [4910, { min: 4200, max: 4299 }],
      },
    },
  },
  'CAT:Major': {
    min: 5000,
    max: 7999,
    subcategories: {
      'SUB:Crowns': {
        min: 2600,
        max: 2999,
      },
      //fill in codes
      // 'SUB:Bridges': {},
      'SUB:Implants': {
        min: 6000,
        max: 6999,
        excluded: { min: 6000, max: 6999 },
      },
      'SUB:Prosthodontics': {
        // Placeholder values. Verify!
        'SUB:Dentures': {
          min: 5000,
          max: 5999,
        },
      },
    },
  },
  // Verify
  'CAT:Ortho': {
    min: 8000,
    max: 8999,
  },
};

type TSubcategoryRange = {
  subcategory: V3.TSubcategory;
  range: { min: number; max: number };
};

const subcategoryRanges: { [key: string]: TSubcategoryRange } = {
  'SUB:Diagnostic': {
    subcategory: 'SUB:Diagnostic',
    range: {
      min: 0,
      max: 999,
    },
  },
  'SUB:Exams': {
    subcategory: 'SUB:Exams',
    range: {
      min: 0,
      max: 199,
    },
  },
  'SUB:X-Ray': {
    subcategory: 'SUB:X-Ray',
    range: {
      min: 200,
      max: 399,
    },
  },
  'SUB:Prophylaxis': {
    subcategory: 'SUB:Prophylaxis',
    range: {
      min: 1100,
      max: 1199,
    },
  },
  'SUB:Restorative': {
    subcategory: 'SUB:Restorative',
    range: {
      min: 2000,
      max: 2599,
    },
  },
  'SUB:Oral surgery': {
    subcategory: 'SUB:Oral & Maxillofacial Surgery',
    range: {
      min: 7000,
      max: 7899,
    },
  },
  'SUB:Endodontics': {
    subcategory: 'SUB:Endodontics',
    range: {
      min: 3000,
      max: 3999,
    },
  },
  'SUB:Periodontics': {
    subcategory: 'SUB:Periodontics',
    range: {
      min: 4000,
      max: 4999,
    },
  },
  'SUB:Crowns': {
    subcategory: 'SUB:Crowns',
    range: {
      min: 2600,
      max: 2999,
    },
  },
  // 'SUB:Bridges': {},
  'SUB:Implants': {
    subcategory: 'SUB:Implant Services',
    range: {
      min: 6000,
      max: 6999,
    },
  },
  // Need code range
  // 'SUB:Prosthodontics': {
  // },
  // Placeholder values. Verify!
  'SUB:Dentures': {
    subcategory: 'SUB:Dentures',
    range: {
      min: 5000,
      max: 5999,
    },
  },
};

const getCategoryForCode = (code: V2.TAdaCode) => {
  const numericCode = Number(code.split('D')[1]);
  let finalCategory = '';

  forIn(fallbackTree, (range, category) => {
    if (range.min <= numericCode && range.max >= numericCode) {
      finalCategory = category;
    }
  });

  // Should it stay?
  // Should not be included in the fallback tree
  if (numericCode >= 9000) {
    finalCategory = 'CAT:Adjunctive General Services';
  }

  return finalCategory;
};

const getSubcategoryForCode = (
  code: V2.TAdaCode,
): V3.TSubcategory | undefined => {
  const numericCode = Number(code.split('D')[1]);
  const subcategories: TSubcategoryRange[] = [];

  forIn(subcategoryRanges, (subcategoryRange) => {
    if (
      subcategoryRange.range.min <= numericCode &&
      subcategoryRange.range.max >= numericCode
    ) {
      subcategories.push(subcategoryRange);
    }
  });

  if (subcategories.length === 1) {
    return subcategories[0].subcategory;
  } else if (subcategories.length > 1) {
    let minRange = Infinity;
    let bestSubcategory: V3.TSubcategory | undefined = undefined;
    subcategories.forEach((subcategory) => {
      if (subcategory.range.max - subcategory.range.min < minRange) {
        minRange = subcategory.range.max - subcategory.range.min;
        bestSubcategory = subcategory.subcategory;
      }
    });
    return bestSubcategory;
  } else {
    return undefined;
  }
};

const categories = [
  { from: 0, to: 999, name: 'Diagnostic' },
  { from: 1000, to: 1999, name: 'Preventive' },
  { from: 2000, to: 2599, name: 'Restorative' },
  { from: 2600, to: 2999, name: 'Major Restorative' },
  { from: 3000, to: 3999, name: 'Endodontics' },
  { from: 4000, to: 4999, name: 'Periodontics' },
  { from: 5000, to: 5899, name: 'Removable Prosthodontics' },
  { from: 5911, to: 5999, name: 'Maxillofacial Prosthetics' },
  { from: 6000, to: 6999, name: 'Implant Services' },
  { from: 7000, to: 7999, name: 'Oral and Maxillofacial Surgery' },
  { from: 8000, to: 8999, name: 'Orthodontics' },
  { from: 9000, to: 9999, name: 'Adjunctive General Services.' },
];

const DECIMAL_NOTATION_BASE = 10;

export function getOldCategoryByCode(code: V2.TAdaCode): string {
  if (code[0] !== 'D') {
    throw new Error(`God a unknown adacode format: ${code}`);
  }

  const codeNumber = parseInt(code.substring(1), DECIMAL_NOTATION_BASE);
  if (isNaN(codeNumber) || !isFinite(codeNumber)) {
    throw new Error(`God a invalid adacode number: ${code}`);
  }

  for (const category of categories) {
    if (category.from <= codeNumber && category.to >= codeNumber) {
      return category.name;
    }
  }

  console.warn(`Unknown category code: ${code}`);

  return 'unknown';
}
