import React from 'react';
import { MainSpaceLayoutProps } from './MainSpaceLayout.type';
import { useStyles } from './MainSpaceLayout.style';

export const MainSpaceLayout: React.FC<MainSpaceLayoutProps> = ({ children }: MainSpaceLayoutProps) => {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};
