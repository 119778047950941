
import { makeStyles } from '@mui/styles';
import { ThemeInterface } from './MainSpaceLayout.type';

export const useStyles = makeStyles((theme: ThemeInterface) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    paddingRight: '0px',
    boxShadow: '0px 4px 20px 0px rgba(128, 128, 128, 0.0)',
    marginBottom: '10px',
    marginRight: '0px',
    backgroundColor: 'white',
    zIndex: 4
  },
  appBarShift: {
    paddingRight: '0px',
    width: 'calc(100%)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
    minHeight: '60px !important'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: 'white'
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2) + ' !important',
  },
}));