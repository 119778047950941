import React, { useEffect } from 'react';
import { CustomWindow } from '../index.types';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FrameLoading } from '../Components/FrameLoading';


declare const window: CustomWindow;

export const withSplashRemove = (WrappedComponent: React.FC) => {

  const ComponentWithSplashRemove = (props: React.ComponentProps<typeof WrappedComponent>) => {
    useEffect(() => {
      if (window.splashExists()) {
        window.removeSplash();
      }
    }, []);
    return <WrappedComponent {...props} />;
  };

  return ComponentWithSplashRemove;
};

export const withAuth = (impl: React.ComponentType<object>) => withAuthenticationRequired(impl, {
  onRedirecting: () => <FrameLoading alternateLoading zIndex={2000} overAll isLoading={true}>
    <></>
  </FrameLoading>,
  returnTo: '/handleLogin',
  loginOptions: {
    authorizationParams: {
      redirect_uri: `${window.location.origin}/handleLogin`
    }
  }
});