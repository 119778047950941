import React, { useEffect } from 'react';
import { FrameLoading } from '../../../Components/FrameLoading';
import { Label, PaperContainer, OrganizationPaper, HeaderBox, HeaderTitle, FormContainer } from '../OrganizationPage/OrganizationPage.style';
import Logo from '../../../Assets/logo.svg';
import { getEnvValue, refreshEnvs } from '../../../Utils/FrontEnvs';
import { Interface } from 'verrific-plus-schema';
import { LogoImg, MessageSpanCenter, MessageSpanRight } from './MaintananceLock.style';

const MaintananceLockPage = () => {

  const runTimer = () => {
    setTimeout(async () => {
      await refreshEnvs();
      const isUnderMaintanance = await getEnvValue(Interface.FlagName.MAINTANANCE_LOCK);
      if (isUnderMaintanance) {
        runTimer();
      } else {
        location.reload();
      }
    }, 10000);
  };

  useEffect(() => {
    runTimer();
  });

  return (
    <FrameLoading isLoading={false}>
      <PaperContainer>
        <OrganizationPaper elevation={3}>
          <HeaderBox>
            <LogoImg
              src={Logo} alt='VeriSmart' />
            <HeaderTitle>Maintanance lock!</HeaderTitle>
          </HeaderBox>
          <FormContainer>
            <Label>
              <br/><br/>
              <MessageSpanCenter>
                We are changing for YOU!
                <br/>
                Currently, the page is not accessible. We are updating VerSmart for you.
                <br/>
                The page will refresh automatically when we finish our work.
              </MessageSpanCenter>
              <br/><br/>
              <MessageSpanRight>
                Sincerely<br/>Verrific Team
              </MessageSpanRight>
            </Label>
          </FormContainer>
        </OrganizationPaper>
      </PaperContainer>
    </FrameLoading>
  );
};

export default MaintananceLockPage;
