import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

export type TAnyObjectLike = {[key: string]: TAnyLike};
export type TAnyLike = TAnyObjectLike | string | number | boolean | TAnyLike[];
export type IHookBody = IHookBodyChange | IHookBodyAdd | IHookBodyDelete;
export interface IHookBodyChange {
  type: 'onChange';
  fromValue: TAnyLike;
  toValue: TAnyLike;
  path: string;
}
export interface IHookBodyAdd {
  type: 'onAdd';
  toValue: TAnyLike;
  path: string;
}
export interface IHookBodyDelete {
  type: 'onDelete',
  fromValue: TAnyLike;
  path: string;
}

export class BizonStore {
  private static _instance: BizonStore;
  public static getInstance(hookAfterInit?: () => void) {
    if (!BizonStore._instance) {
      BizonStore._instance = new BizonStore(hookAfterInit);
    } else if (hookAfterInit) {
      hookAfterInit();
    }
    return BizonStore._instance;
  }

  private LS_KEY = 'bizon_storage';
  private store: TAnyObjectLike = {};
  private isInitialised = false;
  private hookStore: Array<{path: string, hook: (body: IHookBody) => void, id: string}> = [];

  constructor(hookAfterInit?: () => void) {
    (window as any).getBizonStore = () => {
      return this.store;
    };
    this._loadFromCache();
    if (hookAfterInit) {
      hookAfterInit();
    }
  }

  _loadFromCache() {
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.startsWith(`${this.LS_KEY}_`)) {
        const path = key.replace(`${this.LS_KEY}_`, '');
        let cData = this._copyStore();
        const pathParts = this._decomposePath(path);
        const tempPath = [...pathParts];
        tempPath.pop();
        cData = this._createMissingPathElements(tempPath, cData);
        const tempPath2 = [...pathParts];
        cData = this._setValue(tempPath2, cData, JSON.parse(value));
        this.store = cData;
      }
    });
    this.isInitialised = true;
  }

  _copyStore() {
    return cloneDeep(this.store);
  }

  _decomposePath(path: string) {
    return path.split('.').map(el => el.trim());
  }

  _createMissingPathElements(path: string[], data: TAnyObjectLike) {
    const pathNode = path.shift();
    if (pathNode) {
      if (data[pathNode] === undefined) {
        data[pathNode] = {};
      }
      data[pathNode] = this._createMissingPathElements(path, data[pathNode] as TAnyObjectLike) as TAnyLike;
    }
    return data;
  }

  _setValue(path: string[], cData: TAnyObjectLike, value: TAnyLike) {
    const pathNode = path.shift();
    const cDataTemp = cData;
    if (pathNode && cDataTemp[pathNode] && path.length) {
      cDataTemp[pathNode] = this._setValue(path, cDataTemp[pathNode] as any, value) as any;
    } else if (pathNode) {
      cDataTemp[pathNode] = value as TAnyObjectLike;
    }
    return cDataTemp;
  }

  _getValue(path: string[]) {
    let data = this.store;
    for (const pt of path) {
      if (data[pt] !== undefined && data[pt] !== null) {
        data = data[pt] as TAnyObjectLike;
      } else {
        return undefined;
      }
    }
    return data;
  }

  _deleteValue(path: string[], cData: TAnyObjectLike) {
    const pathNode = path.shift();
    const cDataTemp = cData;
    if (pathNode && cDataTemp[pathNode] && path.length) {
      cDataTemp[pathNode] = this._deleteValue(path, cDataTemp[pathNode] as any) as any;
    } else if (pathNode) {
      delete cDataTemp[pathNode];
    }
    return cDataTemp;
  }

  storeData (path: string, data: TAnyLike, cache?: boolean, withoutHook?: boolean) {
    const cData = this._copyStore();

    set(cData, path, data);

    if (cache) {
      localStorage.setItem(`${this.LS_KEY}_${path}`, JSON.stringify(data));
    }
    this.store = cData;
  }

  readData(path: string) {
    const data = this._getValue(this._decomposePath(path)) as TAnyLike;
    if (data !== undefined) {
      return data;
    }
    return undefined;
  }

  clearCache(path: string) {
    localStorage.removeItem(`${this.LS_KEY}_${path}`);
  }

  removeData(path: string) {
    let cData = this._copyStore();
    const pathParts = this._decomposePath(path);
    cData = this._deleteValue(pathParts, cData);
    this.store = cData;
    this.clearCache(path);
  }

  getStore() {
    return this.store;
  }
}