import { Interface } from 'verrific-plus-schema';

const PATH = process.env.REACT_APP_PATH ? process.env.REACT_APP_PATH : '';

interface CustomWindow extends Window {
  FRONT_ENVS: {[key: string]: {
    isActive: Interface.FlagValue;
    type: Interface.FlagType;
    value?: string;
  }} | undefined;
}

declare const window: CustomWindow;
window.FRONT_ENVS = undefined;

const initEnvs = async () => {
  // const res = await fetch('/envs.json');
  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // window.FRONT_ENVS = await res.json();
  window.FRONT_ENVS = {};

  const res2 = await fetch(`${PATH}/api/features`);
  const backendFFlags: Interface.IFeatureFlag[] = await res2.json();
  backendFFlags.forEach(flag => {
    if (window.FRONT_ENVS) {
      window.FRONT_ENVS[flag.name] = {
        type: flag.type,
        value: flag.value,
        isActive: flag.isActive
      };
    }
  });
};

export const refreshEnvs = async () => {
  window.FRONT_ENVS = undefined;
  await initEnvs();
};

export const getEnvs = async () => {
  if (!window.FRONT_ENVS || Object.keys(window.FRONT_ENVS).length === 0) {
    await initEnvs();
  }
  return window.FRONT_ENVS;
};

export const isEnvActive = async (name: string) => {
  if (!window.FRONT_ENVS || Object.keys(window.FRONT_ENVS).length === 0) {
    await initEnvs();
  }

  return window.FRONT_ENVS?.[name]?.isActive || false;
};

export const getEnvValue = async (name: string) => {
  if (!window.FRONT_ENVS || Object.keys(window.FRONT_ENVS).length === 0) {
    await initEnvs();
  }
  const env = window.FRONT_ENVS?.[name];
  if (env) {
    switch (env.type) {
    case Interface.FlagType.NONE:
      return env.isActive;
    case Interface.FlagType.STRING:
      return env.value;
    case Interface.FlagType.INTEGER:
      return Number(env.value || -1);
    case Interface.FlagType.OBJECT:
      return JSON.parse(env.value || '{}');
    default:
      return undefined;
    }
  }
  return undefined;
};

