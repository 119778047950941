const ROLES_REALM = 'https://verrific.us/roles';
const ORGANIZATION_REALM = 'https://verrific.us/organization';
const OFFICE_ID_REALM = 'https://verrific.us/officeId';
const INTEGRATION_REALM = 'https://verrific.us/scheduleCredentials';
const OUTSOURCE_ENABLED = 'https://verrific.us/outsourceEnabled';
const PAYMENT_TYPE = 'https://verrific.us/paymentType';
const ABLE_TO_FULLFILLFORM = 'https://verrific.us/ableToFullfillForm';
const EDIT_GLOBAL_CUSTOMIZATION_ID_REALM = 'https://verrific.us/canEditGlobalCustomization';
const EDIT_GLOBAL_CARRIERS_ID_REALM = 'https://verrific.us/canEditGlobalCarriers';

export type TAuthRoles = 'admin' | 'agent' | 'superadministrator' | 'staff' | 'staffmanager' | 'doregistration' |'goduser';

const availableIntegrations = ['Open Dental', 'Dentrix'];

export const enum RolesEnum {
  ADMIN = 'admin',
  AGENT = 'agent',
  SUPERADMIN = 'superadministrator',
  STAFF = 'staff',
  STAFFMANAGER = 'staffmanager',
  DOREGISTRATION = 'doregistration',
  GODUSER = 'goduser'
}

class Auth0VerrificImpl {
  private static __instance: Auth0VerrificImpl;
  static getInstance(): Auth0VerrificImpl {
    if (!Auth0VerrificImpl.__instance) {
      Auth0VerrificImpl.__instance = new Auth0VerrificImpl();
    }
    return Auth0VerrificImpl.__instance;
  }
  private token?: {[key: string]: any};
  private postLoginAction: (() => void) | null = null;

  private constructor() {}

  private is(role: string) {
    const clearListOfRoles: string[] = this.token?.[ROLES_REALM].map((el: string) => el.toLowerCase().split(' ').join('')) || [];
    return clearListOfRoles.includes(role)
  }

  private decodeJWT(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map((character) => {
      return '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const decoded =  JSON.parse(jsonPayload);
    decoded.__raw = token;
    return decoded;
  };

  setToken(token: {[key: string]: any}) {
    this.token = token;
    if (this.postLoginAction) {
      this.postLoginAction();
    }
  }

  setRawToken(token: string) {
    this.token = this.decodeJWT(token);
    if (this.postLoginAction) {
      this.postLoginAction();
    }
  }

  setPostLoginAction(action: () => void) {
    this.postLoginAction = action;
  }

  getToken() {
    return this.token?.__raw;
  }

  getExpirationDate() {
    return new Date(this.token?.exp ? this.token?.exp * 1000 : new Date());
  }

  getRoles() {
    return this.token?.[ROLES_REALM].map((el: string) => el.toLowerCase().split(' ').join('')) || [];
  }

  getIntegration() {
    return this.token?.[INTEGRATION_REALM]
  }

  getIntegrationType() {
    return this.token?.[INTEGRATION_REALM]?.type
  }

  getIntegrationPMSType() {
    return this.token?.[INTEGRATION_REALM]?.pmsType
  }

  getIntegrationData() {
    return this.token?.[INTEGRATION_REALM]?.integration
  }

  getOutsourceAbility() {
    return this.token?.[OUTSOURCE_ENABLED];
  }

  getPaymentType() {
    return this.token?.[PAYMENT_TYPE];
  }

  getAbilityToFullfillForm() {
    if (this.isAgent() || this.isAdmin() || this.isSuperAdmin() || this.isGodModeUser()) {
      return true;
    }
    return this.token?.[ABLE_TO_FULLFILLFORM] || false;
  }

  isSuperAdmin() {
    return this.is(RolesEnum.SUPERADMIN);
  }

  isAdmin() {
    return this.is(RolesEnum.ADMIN);
  }

  isAgent() {
    return this.is(RolesEnum.AGENT);
  }

  isGodModeUser() {
    return this.is(RolesEnum.GODUSER);
  }


  isStaff() {
    return this.is(RolesEnum.STAFF);
  }

  isStaffManager() {
    return this.is(RolesEnum.STAFFMANAGER);
  }

  getUserMetadata() {
    return this.token?.app_metadata;
  }

  getUserData() {
    return this.token;
  }

  getOfficeId() {
    return this.token?.[OFFICE_ID_REALM];
  }

  getEditGlobalCustomizationPrivilage() {
    return this.token?.[EDIT_GLOBAL_CUSTOMIZATION_ID_REALM];
  }

  getEditGlobalCarriersPrivilage() {
    return this.token?.[EDIT_GLOBAL_CARRIERS_ID_REALM];
  }

  isDODuringRegistration() {
    return !!this.getToken() && !this.isStaff() && !this.isStaffManager() && !this.getOfficeId() && !this.isCompanyAuthorized();
  }

  isAuthorized() {
    return !!this.getToken() && (this.isAgent() || this.isAdmin() || this.isSuperAdmin() || this.isStaff() || this.isStaffManager() || this.isGodModeUser());
  }

  isCompanyAuthorized() {
    return !!this.getToken() && (this.isAgent() || this.isAdmin() || this.isSuperAdmin() || this.isGodModeUser());
  }

  isDentalOfficeAuthorized() {
    return !!this.getToken() && (this.isStaff() || this.isStaffManager());
  }

  isIntegrated() {
    return !!this.getIntegration();
  }

  isPMSIntegrated() {
    return availableIntegrations.includes(this.getIntegrationPMSType())
  }

  getOrganization() {
    return this.token?.[ORGANIZATION_REALM];
  }
}

export const Auth0Verrific = Auth0VerrificImpl.getInstance();