import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { FrameLoadingProps } from './FrameLoading.type';
import { useStyles } from './FrameLoading.style';
import logo from '../Assets/logo.svg';
import DynamicLogo from './DynamicLogo/DynamicLogo';

export const FrameLoading: React.FC<FrameLoadingProps> = ({
  isLoading,
  children,
  backdrop,
  size,
  zIndex,
  overAll,
  spinnerInfo,
  alternateLoading,
  styles
}: FrameLoadingProps) => {
  const classes = useStyles();
  const style: React.CSSProperties = {
    position: overAll ? 'fixed' : undefined,
    width: overAll ? '100%' : undefined,
    height: overAll ? '100%' : undefined,
    zIndex: zIndex
  };

  const elRef = React.createRef<HTMLDivElement>();

  const [showAlternate, setShowAlternate] = useState(false);

  const disableAlternate = async () => {
    if (((elRef.current as HTMLElement)?.children[0] as HTMLElement)?.style) {
      ((elRef.current as HTMLElement).children[0] as HTMLElement).style.opacity = '0';
    }
    setTimeout(() => {
      setShowAlternate(false);
    }, 500);
  };

  useEffect(() => {
    if (alternateLoading) {
      if (isLoading) {
        setShowAlternate(true);
      } else {
        disableAlternate();
      }
    }
  }, [isLoading]);

  return (
    <div className={classes.container} style={{width: '100%', ...styles}}>
      {children}
      {isLoading && !alternateLoading && (
        <div style={zIndex || overAll  ? style : undefined} className={clsx(classes.loaderRoot, {[`${classes.backdrop}`]: backdrop !== false})}>
          <CircularProgress color="primary" size={size} className={classes.loaderSpiner} />
          {!!spinnerInfo && (<div className={classes.infoText}>{spinnerInfo}</div>)}
        </div>
      )}
      {showAlternate && alternateLoading && (
        <div ref={elRef} style={zIndex || overAll  ? style : undefined} className={clsx(classes.loaderRoot, {[`${classes.backdropAlternate}`]: backdrop !== false})}>
          <div className={classes.splash}>
            {/* <img className={classes.splashImg} src={logo}/> */}
            <DynamicLogo className={classes.splashImg} type='LOADING'/>
          </div>
        </div>
      )}
    </div>
  );
};
